export default {
  page: {
    title: {
      401: 'Accès restreint aux utilisateurs connectés',
      403: 'Accès restreint aux participants',
      404: 'Page introuvable',
      default: "Une erreur s'est produite",
    },
    subtitle: {
      401: `Veuillez vous connecter pour voir cette page.`,
      403: 'Veuillez vous inscrire pour voir cette page.',
      404: `Cette page n'existe plus.`,
      default: 'Hum... Quelque chose s’est mal passé. Veuillez réessayer plus tard.',
    },
    cta: {
      401: 'Connexion',
      403: 'Inscrivez-vous',
      404: "Retourner à l'accueil",
      default: "Retourner à l'accueil",
    },
  },
  server_validation: {
    required: 'Ce champ est requis.',
    compare: 'Ce champ doit être identique.',
    not_found: 'Cette entité est introuvable.',
    already_exist: 'Cette entité existe déjà.',
    max_length: "Vous avez entré trop de caractères. Veuillez raccourcir l'entrée.",
    email: 'Veuillez saisir un courriel valide.',
    authorization_error: "Vous n'avez pas la permission d'effectuer cette action.",
    user_already_event_participant: "L'utilisateur participe déjà à l'événement.",
    user_already_on_waitlist: 'Vous êtes déjà sur la liste d’attente.',
    user_creation: 'Une erreur est survenue lors de la création du participant.',
    upload_failed: 'Une erreur est survenue lors du chargement du fichier.',
    invalid_file_type: 'Le format de fichier est invalide.',
    missing_presenter: 'Vous devez avoir au moins un auteur qui est présentateur.',
    invalid_author: 'Au moins un auteur a des informations manquantes.',
    duplicate_author_email: 'Les courriels des auteurs doivent être uniques.',
    required_maxQuantity:
      "Le formulaire ne peut pas être rempli: toutes les options d'un champ obligatoire ne sont pas disponibles.",
    user_already_event_submitter: "L'utilisateur a déjà soumis son résumé.",
    user_must_be_registered: "L'utilisateur n'est pas inscrit à l'événement.",
    registration_unavailable: "L'événement n'accepte pas de nouvelles inscriptions.",
    submission_unavailable: "L'événement n'accepte pas de nouvelles soumissions.",
    field_choice_quantity: 'Cette option n’est pas en quantité suffisante pour être sélectionnée.',
    field_choice_late_cost:
      'Le prix de certains items a changé depuis le chargement de la page. Veuillez valider les montants avant de continuer.',
    missing_billinginfo: 'Vous devez saisir vos informations de facturation.',
    missing_payment_method: "Aucune méthode de paiement n'a été configurée pour cet événement.",
    login_invalid: 'Tentative de connexion invalide.',
    field_choice_date: 'Cette option n’est pas disponible.',
    field_not_found: "Un champ n'étant pas associé à ce formulaire a été soumis.",
    session_full: 'Le nombre maximal de participant est atteint. Revenez plus tard.',
    payment_error: "Une erreur s'est produite avec votre carte.",
    payment_maintenance: 'Le système de paiement TouchNet est présentement en maintenance. Revenez plus tard.',
    payment_requires_action: 'Une action est requise de votre part afin de terminer le paiement.',
    payment_auth_error: "L'authentification du mode de paiement a échoué.",
    coupon_code_invalid: "Ce code coupon n'est pas valide.",
    stripe: {
      expired_card: "La carte a expiré. Vérifiez la date d'expiration ou utilisez une autre carte.",
      incorrect_address:
        'L’adresse de la carte est incorrecte. Vérifiez l’adresse de la carte ou utilisez une autre carte.',
      incorrect_cvc:
        'Le code de sécurité de la carte est incorrect. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      incorrect_number: 'Le numéro de carte est incorrect. Vérifiez le numéro de la carte ou utilisez une autre carte.',
      incorrect_zip:
        'Le code postal de la carte est incorrect. Vérifiez le code postal de la carte ou utilisez une autre carte.',
      invalid_characters: 'Le numéro de la carte contient des caractères qui ne sont pas pris en charge.',
      invalid_cvc:
        'Le code de sécurité de la carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      invalid_expiry_month:
        "Le mois d'expiration de la carte est incorrect. Vérifiez la date d'expiration ou utilisez une autre carte.",
      invalid_expiry_year:
        "L’année d’expiration de la carte est incorrecte. Vérifiez la date d'expiration ou utilisez une autre carte.",
      invalid_number:
        "Le numéro de carte n'est pas valide. Vérifiez les détails de la carte ou utilisez une autre carte.",
      postal_code_invalid: 'Le code postal fourni était incorrect.',
      processing_error:
        "Une erreur s'est produite lors du traitement de la carte. Réessayez plus tard ou avec un autre mode de paiement.",
      taxes_calculation_failed: 'Le calcul de la taxe pour la commande a échoué.',
      card_declined: 'La carte a été refusée.',
      generic_decline: 'La carte a été refusée pour une raison inconnue.',
      card_not_supported: "La carte ne prend pas en charge ce type d'achat.",
      call_issuer: 'La carte a été refusée pour une raison inconnue.',
      lost_card: 'Le paiement a été refusé car la carte est déclarée perdue.',
      fraudulent: 'La carte a été refusée pour une raison inconnue.',
      insufficient_funds: "La carte ne dispose pas de fonds suffisants pour finaliser l'achat.",
      do_not_honor:
        "La carte est refusée. Veuillez contacter votre banque pour qu'elle examine la transaction et détermine pourquoi elle a été refusée.",
      invalid_account:
        "Veuillez contacter votre banque pour qu'elle examine la transaction et détermine pourquoi elle a été refusée.",
      transaction_not_allowed:
        "La transaction n'est pas autorisée. Veuillez contacter l'émetteur de votre carte pour plus d'informations.",
    },
    paysafe: {
      billing_street_length: `L'adresse ne peut pas comporter plus de 50 caractères. Vérifiez l'adresse de la carte ou utilisez une autre carte.`,
      billing_city_length:
        'La ville ne peut pas comporter plus de 40 caractères. Vérifiez la ville de la carte ou utilisez une autre carte.',
      billing_zip_length:
        'Le code postal ne peut pas comporter plus de 10 caractères. Vérifiez le code postal de la carte ou utilisez une autre carte.',
      3009: 'Votre demande a été refusée par la banque émettrice.',
      3015: 'La banque vous a demandé de traiter la transaction manuellement en appelant la société de carte de crédit du titulaire de la carte.',
      3022: 'La carte a été refusée en raison de fonds insuffisants.',
      3024: `Votre demande a été refusée, car la banque émettrice n'autorise pas la transaction pour cette carte.`,
      3403: 'Vous avez déjà traité le nombre maximum de remboursements autorisés.',
      3406: "La transaction que vous tentez de rembourser n'a pas encore été traitée. Il n'y a pas de fonds disponibles pour le remboursement.",
      3405: 'La transaction que vous tentez de rembourser a expirée.',
      3415: 'Vous ne pouvez pas annuler cette transaction.',
      3407: 'La transaction que vous avez fourni est introuvable.',
    },
    touchnet: {
      9: "Il y a une erreur d'intégration avec la passerelle de paiement (TouchNet). Veuillez contacter l'organisateur de l'événement.",
      1724: "La vérification de l'adresse de la carte (AVS) a échoué. L'adresse saisie ne correspond pas à la valeur attendue au niveau de l'émetteur de la carte.",
      1741: 'Le code de sécurité de la carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      1757: "Le compte est temporairement verrouillé en raison d'un trop grand nombre d'échecs de tentatives d'autorisation. Veuillez attendre que le blocage soit levé ou utiliser un autre mode de paiement.",
      1758: 'La carte a été refusée pour une raison inconnue. Veuillez réessayer plus tard. (1758)',
      1761: "La carte a expiré. Vérifiez la date d'expiration ou utilisez une autre carte.",
      1766: 'Le montant des frais dépasse le plafond de retrait fixé par la banque émettrice de la carte.',
      1777: "Échec de l'autorisation de paiement. Veuillez contacter l'émetteur de votre carte pour plus d'informations.",
      1923: "La carte a été refusée pour une raison inconnue. Veuillez contacter l'organisateur de l'événement. (1923)",
      1924: 'La carte a été refusée pour une raison inconnue. Veuillez réessayer plus tard. (1924)',
      1967: 'La carte a été refusée pour une raison inconnue. Veuillez réessayer plus tard. (1967)',
      1968: 'La carte a été refusée pour une raison inconnue. Veuillez réessayer plus tard. (1968)',
      2900: 'Le montant  est supérieur au montant disponible pour le remboursement.',
    },
    globalpayments: {
      invalid_exp_month:
        'Le mois d’expiration de la carte n’est pas valide. Vérifiez la date d’expiration de la carte ou utilisez une autre carte.',
      invalid_card_number:
        'Le numéro de la carte n’est pas valide. Vérifiez le numéro de la carte ou utilisez une autre carte.',
      invalid_cvv:
        'Le code de sécurité n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      missing_exp_year: 'L’année d’expiration de la carte est manquante.',
      missing_exp_month: 'Le mois d’expirtion de la carte est manquant.',
      missing_card: 'Le numéro de la carte est manquant.',
      missing_cvv: 'Le code de sécurité est manquant.',
      incorrect_cvc:
        'Le code de sécurité de la carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      40085: 'La carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      declined: {
        101: 'La carte a été refusée.',
        102: 'La carte a été refusée.',
        103: 'La carte a été refusée.',
        108: 'La carte a été refusée par la banque.',
        111: 'La carte a été refusée. Authentification requise.',
      },
      50013: 'Erreur de communication. Veuillez réessayer plus tard. (50013)',
    },
    moneris: {
      tokenization: {
        940: "Requête invalide (940). Veuillez contacter l'organisateur de l'événement.",
        941: "Erreur lors de la tokenization (941). Veuillez contacter l'organisateur de l'événement.",
        942: "Requête invalide (942). Veuillez contacter l'organisateur de l'événement.",
        943: 'Le numéro de la carte est invalide.',
        944: 'La date d’expiration de la carte est invalide (MM/YY).',
        945: 'Le code de sécurité est invalide.',
        tokenization_error: "Erreur lors de la tokenization. Veuillez contacter l'organisateur de l'événement.",
      },
      '050': 'La carte a été refusée.',
      '051': 'Carte expirée.',
      '052': 'Carte non acceptée.',
      '053': 'La carte a été refusée pour une raison inconnue. Veuillez réessayer plus tard. (053)',
      '054': 'La carte a été refusée pour une raison inconnue. Veuillez réessayer plus tard. (054)',
      '055': 'Carte non acceptée.',
      '056': 'Carte non acceptée.',
      475: 'La date d’expiration de la carte n’est pas valide.',
      476: 'La carte a été refusée.',
      477: 'Le numéro de la carte n’est pas valide.',
      481: 'La carte a été refusée.',
      482: 'La carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      483: 'La carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      484: 'La carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.',
      485: 'Échec de l’autorisation de paiement. Veuillez contacter l’émetteur de votre carte pour plus d’informations.',
      486: 'Le code de sécurité de la carte n’est pas valide.',
      487: 'Le code de sécurité de la carte n’est pas valide.',
      489: 'Le code de sécurité de la carte n’est pas valide.',
      490: 'Le code de sécurité de la carte n’est pas valide.',
      email_length_exceeded: "L'adresse e-mail dépasse la limite maximale de 60 caractères.",
    },
    unspecified_error: 'Une erreur est survenue.',
    min: 'Ce champ doit avoir une valeur supérieure.',
    max: 'Ce champ doit avoir une valeur inférieure.',
    presentations_exceed_session_time: 'Le temps des présentations ne doit pas excéder celui de la session',
    invalid_password: "Ce mot de passe n'est pas valide.",
    invalidusername: 'Ce courriel est invalide.',
    duplicateusername: "Ce nom d'utilisateur est déjà utilisé. Veuillez vous connecter.",
    duplicate_email: 'Ce courriel est déjà utilisé.',
    invalid_url_segment: 'Ce champ doit contenir uniquemment des caractères alphanumériques.',
    url_segment_duplicate: 'Cette valeur est déjà utilisée.',
    date_after: 'Ce champ doit être postérieur au précédent.',
    user_existing_permissions: 'Cet utilisateur est déjà associé à ce rôle.',
    invalid_on_seed: 'Action non disponible sur les données de test.',
    late_cost_need_amount_and_date: 'Le montant et la date sont nécessaires à la configuration du coût tardif.',
    max_words_not_allowed: "La limite de mots ne s'applique pas à ce type de champ.",
    field_not_cloneable: 'Ce champ ne peut pas être cloné.',
    registration_date_after_event_enddate:
      "La date de fin des inscriptions ne peut pas être après la date de l'événement. ({date})",
    submission_date_after_event_enddate:
      "La date de fin des soumissions ne peut pas être après la date de l'événement. ({date})",
    date_after_event_enddate: "La session ne peut pas être après la date de l'événement. ({date})",
    date_blocked: "Il n'est plus possible de modifier cette date une fois l'événement publié",
    invalid_token: 'Ce jeton invalide.',
    invalid_zoom_meeting_id: 'Numéro de réunion invalide.',
    email_confirmed: 'Votre compte est déjà activé, veuillez vous connecter.',
    too_many_attempts: 'Trop de tentatives de connexion, réessayez dans 2 minutes.',
    date_greater_than_today: "La date doit être supérieure ou égale à celle d'aujourd'hui.",
    vps_disabled: "La fonctionnalité de session d'affiches virtuelle n’est pas activée",
    vps_quota_exceeded: "Vous avez dépassé le nombre d'heures dédiées à la session d'affiches virtuelle",
    url: 'Ce champ doit contenir un lien valide (ex: https://fourwaves.com)',
    session_overlap: 'Une autre session utilise cette salle à l’heure choisie',
    license_not_found: 'Cette licence est introuvable.',
    license_already_used: 'Cette licence a déjà été utilisée.',
    sequential_id_already_exist: 'Cet ID existe déjà',
    invalid_submission_decision: 'La décision pour cette soumission est invalide',
    maxqty_must_be_greater_than_zero: 'Ce champ doit avoir une valeur supérieure à zéro.',
    not_on_waitlist: "Le participant est déjà retiré de la liste d'attente.",
    code_already_used: 'Ce code de passerelle a déjà été utilisé.',
    code_not_found: 'Ce code est introuvable.',
    alphanumeric: 'Lettres et chiffres uniquement.',
    content_invalid: 'Contenu invalide (erreur client/serveur)',
    license_organization_not_valid_with_event: 'Ce code est associé à une autre organisation.',
    license_inactive: 'Ce code est associé à une licence inactive.',
    not_refundable: "Il n'est pas possible de rembourser cette transaction.",
    max_file_size: 'Téléversement échoué: taille du fichier trop grande ({size})',
    invalid_file_extension: 'Téléversement échoué: type de fichier invalide ({extension})',
  },
};
