
import { Prop, Component, Ref, Model, mixins, Watch } from 'nuxt-property-decorator';
import { TranslateResult } from '../../../../types/i18n';
import Hint from '../../../Hint.vue';
import { Collapsible } from '../../../../components/layout';
import InputTextMixin from './InputTextMixin';

@Component({ components: { Collapsible, Hint } })
export default class InputText extends mixins(InputTextMixin) {
  @Prop({ default: 'text' }) readonly type!: string;
  @Prop({ type: String }) readonly leadingText!: string | TranslateResult;
  @Prop({ type: String, default: null }) readonly leadingIcon!: string;
  @Prop(Boolean) readonly useMask!: boolean;
  @Prop(Boolean) readonly hideRequiredMark!: boolean;

  @Ref() inputElement?: HTMLInputElement;

  @Model('input', { type: [String, Number], default: null }) readonly value!: string | number | null;

  get innerValue() {
    if (!this.value) return '';
    if (this.leadingText && this.useMask) {
      return String(this.value).replace(String(this.leadingText), '');
    }
    return this.value;
  }

  set innerValue(newValue: string | number) {
    if (this.useMask && this.leadingText) {
      newValue ? this.$emit('input', `${this.leadingText}${newValue}`) : this.$emit('input', '');
    } else {
      this.$emit('input', newValue);
    }
  }

  get inputClassNames() {
    return {
      '-destructive': this.hasValidationError,
      '-with-leading-text': this.leadingText,
    };
  }

  get footerText() {
    if (this.hasValidationError) return this.firstValidationError;
    return this.help || '';
  }

  async mounted() {
    await this.$nextTick();
    if (this.autofocus) this.inputElement?.focus();
  }

  @Watch('innerValue.length')
  onValueChange(newValue: string | number, oldValue: string | number) {
    if (newValue !== oldValue && this.hasValidationError) this.triggerValidation();
  }

  public onBlur() {
    this.$emit('blur');
    // prevent required validation on blur
    if (this.innerValue) this.triggerValidation();
  }
}
