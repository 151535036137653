import { EventDto, getShortAddress } from '../services';

export interface AddressMixins {
  getShortAddress(event: Partial<EventDto>): string;
  getPlace(event: Partial<EventDto>): string;
}

export const addressMixins: AddressMixins = {
  getShortAddress(event) {
    if (event.type === 'Online') {
      return this.$t('global.online_event');
    }

    if (!event.locality && !event.country) {
      return this.$t('global.no_location');
    }

    return getShortAddress(event);
  },
  getPlace(event) {
    if (!event.locality && !event.country) {
      return this.$t('global.no_location');
    }

    if (event.place && this.transl8(event.place)) {
      return this.transl8(event.place);
    }

    return getShortAddress(event);
  },
};
