
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Size, Saturation, Color } from '../types';
import { rem } from '../helpers';

@Component
export default class Badge extends Vue {
  @Prop({ type: String, default: Color.Gray }) readonly color!: Color;
  @Prop({ type: String, default: Saturation.Light }) readonly saturation!: Saturation;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop(String) readonly iconLeading!: string;
  @Prop(String) readonly iconTrailing!: string;

  Sizes = Size;

  isIconOnly = false;

  get sizeMap() {
    return this.isIconOnly
      ? {
          sm: {
            '--height': rem(20),
            '--width': rem(20),
            '--padding': 0,
          },
          md: {
            '--height': rem(28),
            '--width': rem(28),
            '--padding': 0,
          },
          lg: {
            '--height': rem(32),
            '--width': rem(32),
            '--padding': 0,
          },
        }
      : {
          sm: {
            '--height': rem(22),
            '--padding': rem(10),
          },
          md: {
            '--height': rem(24),
            '--padding': rem(10),
          },
          lg: {
            '--height': rem(28),
            '--padding': rem(12),
          },
        };
  }

  get sizingProperties() {
    return this.sizeMap[this.size];
  }

  get elementClassNames() {
    return {
      '-icon-margin': !this.isIconOnly,
      '-bg-white': this.saturation === Saturation.None,
      '-border-dark': this.saturation !== Saturation.Light,
    };
  }

  get badgeClassNames() {
    return [this.elementClassNames, `-${this.color} -${this.Sizes[this.size]}`];
  }

  created() {
    this.detectSlotContent();
  }

  beforeUpdate() {
    this.detectSlotContent();
  }

  public detectSlotContent() {
    this.isIconOnly = !this.$slots.default?.length;
  }
}
