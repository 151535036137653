
import { Vue, Component, Prop, Watch, Emit } from 'nuxt-property-decorator';
import { Alignment, GlobalFilterEvents } from '../../types';
import { rem } from '../../helpers';
import Collapsible from './Collapsible.vue';

@Component({
  components: {
    Collapsible,
  },
})
export default class ContextualMenu extends Vue {
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop({ type: Number, default: null }) readonly trayMaxHeight!: number | null;
  @Prop({ type: String, default: Alignment.End }) readonly alignment!: string;

  isOpen = false;

  GlobalFilterEvents = GlobalFilterEvents;

  @Emit() onTrayClose() {}
  @Emit() onToggle(_isOpen: boolean) {}

  get sizeMap() {
    return {
      sm: { '--tray-width': rem(200) },
      md: { '--tray-width': rem(300) },
    };
  }

  get styles() {
    const maxHeight = this.trayMaxHeight ? `${this.trayMaxHeight}px` : 'auto';
    return {
      ...this.sizeMap[this.size],
      '--tray-max-height': maxHeight,
    };
  }

  get rootClassNames() {
    return {
      '-align-left': this.alignment === Alignment.Start,
      '-align-center': this.alignment === Alignment.Center,
    };
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;
    if (isOpen) {
      document.body.addEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$on(GlobalFilterEvents.SelectOpen, this.closeTray);
    } else {
      document.body.removeEventListener('click', () => {
        this.closeTray();
      });
      this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
    }
    this.onToggle(isOpen);
  }

  beforeDestroy() {
    document.body.removeEventListener('click', () => {
      this.closeTray();
    });
    this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeTray);
  }

  public onButtonClick() {
    if (!this.isOpen) this.$nuxt.$emit(GlobalFilterEvents.SelectOpen);
    this.isOpen = !this.isOpen;
  }

  public toggleTray() {
    this.isOpen = !this.isOpen;
  }

  public closeTray() {
    this.isOpen = false;
    this.onTrayClose();
  }
}
