import { addSharedLocales } from 'fourwaves-shared';

export default addSharedLocales('en', {
  countries: require('fourwaves-shared/src/core/countries.en.json'),
  provinces: require('fourwaves-shared/src/core/provinces.en.json'),
  currencies: require('fourwaves-shared/src/core/currencies.en.json'),
  fourwaves_badge: {
    title: 'Powered by',
    link: 'https://fourwaves.com/?utm_source=logo&utm_medium=eventwebsite',
  },
  footer: {
    title: 'Organize your next event using Fourwaves.',
    cta_button: 'Learn More',
    cta_button_link: 'https://fourwaves.com/?utm_source=footer&utm_medium=eventwebsite',
    copyright: 'all rights reserved',
    terms_of_service: 'Terms of use',
    terms_of_service_link: 'https://fourwaves.com/terms-of-use/',
    privacy_policy: 'Privacy policy',
    privacy_policy_link: 'https://fourwaves.com/privacy-policy/',
  },
  navigation: {
    header_event: 'This event',
    login: 'Log in',
    register_form: 'My registration',
    submission_form: 'My abstract',
    organizer_panel: 'Organizer panel',
    account: 'Account',
    registrations: 'My registrations',
    submissions: 'My submissions',
    transactions: 'My transactions',
    events: 'My events',
    organizations: 'My organizations',
    more: 'More',
    burger: 'Menu',
    aria_nav: 'Main navigation',
    aria_burger: 'Toggle menu',
    lang_switch: 'Naviguer en',
    edit_event: 'Edit event',
  },
  live_banner: {
    title: 'The Event is Live Today',
    button: 'Access the Event',
  },
  file_upload: {
    title: 'Title',
  },
  form: {
    breadcrumb: {
      form: 'Form',
      billing: 'Payment',
      confirmation: 'Confirmation',
      submission_confirmation_header: 'Submission completed',
      registration_confirmation_header: 'Registration complete',
      waitlist_confirmation: 'You are on the waiting list',
    },
    summary: {
      submit: 'Submit',
      additional_info_content: 'For questions, please contact',
      coupon_code: 'Code',
      payment: {
        confirm_and_pay: 'Continue to payment',
        pay_now: 'Pay now',
        price_caluclation_spinner: 'Price calculation in progress...',
        payment_notice: 'Payment secured by',
        total: 'Total',
        sub_total: 'Subtotal',
        billing_title: 'Billing Address',
        billing_info: 'Billing Information',
        email_notice: '(Receipt will be sent to this address)',
        payment: 'Payment',
        payment_method_title: 'Payment method',
        missing_payment_methods: 'No payment method has been configured for this event.',
        coupon_code: 'Coupon Code',
        remove_coupon_code: 'Remove coupon code',
        methods: {
          credit_card: 'Credit card',
          void: 'Skip payment',
          deferred: 'Invoice Payment',
        },
        credit_card: {
          info: 'Card info',
          number: 'Card number',
          expiry: 'Expiry Date',
          cvv: 'CVV',
        },
        gst: 'GST',
        hst: 'HST',
        qst: 'QST',
        vat: 'VAT',
        pst: 'PST',
        vat_number: 'VAT number',
        tax_rules: {
          none: 'No tax',
          qstallgstall: 'QST 9.975% and GST 5%',
          qstquebecgstcanada: 'QST 9.975% and GST 5% in Quebec, GST 5% rest of Canada',
          qstquebecgstall: 'QST 9.975% and GST 5% in Quebec, GST 5% rest of the world',
          gstall: 'GST 5%',
          gst5canada: 'GST 5% in Canada',
          gst5pst7: 'GST 5% and PST 7%',
          gst10all: 'GST 10%',
          gst15all: 'GST 15%',
          hst13all: 'HST 13%',
          hst15all: 'HST 15%',
          hst13canada: 'HST 13% in Canada',
          vat77all: 'VAT 7.7%',
          vat10all: 'VAT 10%',
          vat20all: 'VAT 20%',
          vat25all: 'VAT 25%',
          vat25denmark: 'VAT 25% in Denmark',
          vat19germany: 'VAT 19% in Germany',
          vat19europe: 'VAT 19% in Europe',
          vat0: 'VAT 0%',
          vatreversecharge0: 'VAT reverse charge 0%',
        },
      },
      discrepancies_notice: 'The price of an item has changed. Please validate the information above.',
      edit: 'Save form',
      ongoing_upload_notice: 'Please wait while files are being uploaded.',
      blocked_edit: 'It is not possible to modify this form.',
      join_waitlist: 'Join waitlist',
      mandatory_warning_title: 'Registration Required',
      mandatory_warning_message:
        'Make sure you’re already registered to this event using the same email address before filling out your submission.',
    },
    confirmation: {
      receipt_title: 'Your receipt',
      receipt_number: 'Receipt Number',
      receipt_action: 'Download PDF',
    },
    edit_email:
      'By changing the email address, the form is transferred to a new user ({newEmail}), which will receive a notification by email. The original owner ({oldEmail}) will loose access to the form. Do you wish to continue?',
    edit_success: 'The form has been successfully saved.',
  },
  registration: {
    subtitle: 'Registration deadline is',
    subtitle_not_started: 'Registrations start',
    subtitle_closed: 'Registrations closed',
    waitlist: 'Waitlist',
    summary: 'Registration summary',
    confirmation: {
      title: 'Your form has been successfully submitted',
      subtitle: `Click the link in the confirmation email sent to <span class='util--main-color'>{email}</span> to activate your account. Sign in to access your forms and specific features on the day of the event.`,
    },
    registration_closed: {
      not_started: 'Please come back',
      logged_out_text: '<a href="{url}">Login</a> to access your registrations.',
      logged_in_text: `Go to your account to access <a href="{url}">your registrations</a>.`,
    },
    state_EventHasEnded: 'The event has ended.',
    state_FullWithWaitingList:
      'The maximum number of participants has been reached. Fill the form below to be placed on the waitlist.',
    state_Full: 'Participant limit reached.',
    state_PeriodHasEnded: 'Registration period is finished.',
    state_PeriodDidNotStart: 'The registration period has not started.',
  },
  submission: {
    subtitle: 'Submission deadline is',
    subtitle_not_started: 'Submissions start',
    subtitle_closed: 'Submissions closed',
    submission_period_finished: 'The submission period is closed.',
    confirmation: {
      title: 'Your form has been successfully submitted',
      subtitle: `Click the link in the confirmation email sent to <span class='util--main-color'>{email}</span> to activate your account. Sign in to access your forms and specific features on the day of the event.`,
    },
    submission_closed: {
      not_started: 'Please come back',
      logged_out_text: '<a href="{url}">Login</a> to access your submissions.',
      logged_in_text: `Go to your account to access <a href="{url}">your submissions</a>.`,
    },
    state_EventHasEnded: 'The event has ended.',
    state_FullWithWaitingList:
      'The maximum number of submissions has been reached. Fill the form below to be placed on the waitlist.',
    state_Full: 'Submission limit reached.',
    state_PeriodHasEnded: 'Submission period is finished.',
    state_PeriodDidNotStart: 'The submission period has not started.',
    author: {
      affiliations: 'Affiliations',
      title_add: 'Add an author',
      title_edit: 'Edit an author',
      press_enter: 'Press enter',
      picker: 'Select an affiliation',
      is_presenter: 'Presenter',
      empty: 'Type an affiliation',
      presenter: 'Presenter',
    },
  },
  validation: {
    required: 'This field is required.',
    is: 'This field must be identical.',
    email: 'Your email address must be valid.',
    ckeditor_max_length: 'You entered too many words. Please shorten the input.',
  },
  global: {
    apply: 'Apply',
    show_more: 'Show more',
    save: 'Save',
    cancel: 'Cancel',
    words: 'Words',
    edit: 'Edit',
    until: 'until',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    confirm_email: 'Confirm email',
    company: 'Company / Organisation',
    add: 'Add',
    unavailable: 'Unavailable',
    please_select: 'Please select...',
    by: 'by',
    to: 'To',
    subject: 'Subject',
    body: 'Message',
    already_registered_message: 'Your account is already activated. Please log in.',
    fw_stripe_mention: 'Your credit card statement will show a charge from FOURWAVES EVENTS.',
    address: {
      street: 'Street address',
      city: 'City',
      country: 'Country',
      province: 'Province/State',
      postal: 'Postal/ZIP',
    },
    payment_methods: {
      stripe: 'Online (Stripe)',
      paysafe: 'Online (Paysafe)',
      alipay: 'Online (Alipay)',
      bypass: 'Skip',
      invoicing: 'Invoicing',
      publisoft: 'Online (Publisoft)',
      void: 'Void',
      touchnet: 'TouchNet',
      globalpayments: 'Global Payments',
      moneris: 'Moneris',
    },
    transaction_status: {
      refund: {
        concluded: 'Refunded',
        pending: 'Pending',
        canceled: 'Canceled',
      },
      charge: {
        concluded: 'Paid',
        pending: 'Pending',
        canceled: 'Unpaid',
      },
    },
    review_status: {
      title: 'To review',
      unassigned: 'Unassigned',
      pending: 'Pending',
      conflictofinterest: 'Conflict of interests',
      completed: 'Completed',
    },
    transaction_type: {
      refund: 'Refund',
      payment: 'Payment',
    },
    event_information: 'Event information',
    home: 'Home',
    password: 'Password',
    login_success: 'You are successfully logged in',
    no_location: 'No location',
    online_event: 'Online event',
    hybrid_event: 'Hybrid event',
    loading: 'Loading data...',
    pagination_prev: 'Previous',
    pagination_next: 'Next',
    pagination_display: 'Display',
    forgot: 'Forgot password or Activate your account',
    send: 'Send',
    forgot_password: 'Forgot your password?',
    forgot_password_success: 'Please check your inbox.',
    download: 'Download',
    read_more: 'Read more',
    close: 'Close',
    from: 'from',
    learn_more: 'Learn more',
    clear_filters: 'Clear filters',
    filters: 'Filters',
    hide_filters: 'Hide filters',
    select_default_placeholder: 'Select an option',
  },
  receipt: {
    title: 'Invoice',
    payment_status: 'Payment status',
    payment_method: 'Payment method',
    card_info: 'Card info',
    billing_address: 'Billed to:',
    date: 'Date',
    name: 'Name',
    event: 'Event',
    amounts_currency: 'Amounts are in',
    item_title: 'Item',
    item_price: 'Price',
    download: 'Download invoice',
    total: 'Total',
    sub_total: 'Subtotal',
    payment_instructions: 'Payment Instructions:',
  },
  event_details: {
    location: 'Location',
    registration_period: 'Registration period',
    submission_period: 'Submission period',
    contact: 'Contact us',
    contact_message: 'If you have any questions, please contact',
  },
  participants: {
    participant: 'No participants | {count} participant | {count} participants',
    message: 'This page lists the participants registered to the event.',
    no_results: 'There are no participants to show',
    search_placeholder: 'Search',
    photo_of: 'Photo of ',
    registration_deadline_is: 'Registration deadline is',
    bookmark_filter: 'My connections',
    online_filter: 'Online',
    back_to_list: 'Back',
    placeholder_text: 'No information available.',
    listing_options: {
      alphabetical: 'A - Z',
    },
  },
  poster_hall: {
    title: 'Poster Hall',
  },
  abstracts: {
    upvote: '0 upvotes | 1 upvote | {count} upvotes',
    bookmark: 'Bookmark',
    bookmarked: 'Bookmarked',
    bookmark_filter: 'Bookmarked',
    comment: '0 comments | 1 comment | {count} comments',
    schedule_filter: 'Session',
    schedule_filter_all: 'See all',
    search_placeholder: 'Search',
    total_count: 'No presentations | {count} presentation | {count} presentations',
    no_presentations: 'No presentations.',
    abstract: 'Details',
    figures: 'Figures',
    slides: 'Slides',
    poster: 'Poster',
    video: 'Video',
    back_to_list: 'Back to list',
    in_discussion: 'in discussion',
    call_presenter: 'Call presenter',
    join: 'Join',
    join_conversation: 'Join conversation',
    enter_conversation: 'Enter conversation room',
    listen_conversation: 'Listen to video conversation',
    open_for_discussion: 'Open for discussion',
    presented_by: 'Presented by ',
    by: 'By',
    presenting_now: 'Presenting now',
    sign_in_to_contact: 'Log in to contact',
    contact: 'Contact',
    contact_presenter_modal: {
      title: 'Contact Presenter',
      send_email: 'Send Email',
      success_message: 'Email sent.',
    },
    login_cta: {
      join_conversation: 'The event is live. Join the conversation.',
      need_to_signed: 'You need to be logged in to participate.',
      sign_in: 'Log in',
      already_registered: 'Already registered?',
      activate_your_account: 'Activate your account',
      not_registered: 'Need to register?',
      open_registration: 'Register now',
    },
    schedule_presentations: {
      title: 'Scheduled in {count} session | Scheduled in {count} sessions',
      description: '* All times are based on',
    },
    other_information: 'Other information',
    listing_options: {
      numerical: '1 - 9',
      alphabetical: 'A - Z',
      upvotes: 'Top voted',
    },
    picture_layout: 'Image view',
    list_layout: 'List view',
    enable_laser: 'Enable laser pointer',
    disable_laser: 'Disable laser pointer',
    follow: 'Follow',
    following: 'Following',
    preparing_poster: 'Poster loading',
    no_results: 'There are no presentations that match your filters.',
  },
  schedule: {
    title: 'Schedule',
    no_schedule: 'There are no sessions to show.',
    time_zone_details: '* All times are based on',
    types: {
      postersession: 'Poster session',
      keynote: 'Keynote',
      oral: 'Oral',
      panel: 'Panel',
      workshop: 'Workshop',
      networking: 'Networking',
      break: 'Break',
      other: 'Other',
    },
    sign_to_access_room: 'Log in to access room',
    register_to_access_room: 'Register to access room',
    back_to_list: 'Back',
    only_participant_can_access_rooms: 'Only registered participants can access the room',
    live: 'Live',
    now: 'Now',
    virtual: 'Virtual',
    minute_countdown: 'In {count} min',
    hour_countdown: 'In {count} hour | In {count} hours',
    day_countdown: 'In {count} day | In {count} days',
    tomorrow: 'Tomorrow',
    cta_poster: 'Go to Poster Hall',
    cta_livestream: 'Join livestream',
    presentation: {
      title: 'Presentations',
      show_all_in_presentations: 'View all in the presentations page',
      no_results: 'There are no presentations in this session.',
    },
    bookmark: 'Bookmark',
    unbookmark: 'Unbookmark',
    bookmarked: 'Bookmarked',
    parallel_sessions: '{count} parallel sessions',
  },
  conferencing: {
    conversation: {
      browser_limitation: `The browser you're using ({browser}) is not compatible.`,
      zoom_browser_limitation:
        'Mobile browsers are not fully supported for zoom calls and might result in a poor experience.',
    },
    confirm_quit: 'You are currently on a virtual call, do you really want to quit?',
    video_call_request_title: 'Conversation request',
    video_call_request_join: 'Join',
    video_call_request_decline: 'Decline',
  },
  questions_and_answers: {
    acronym: 'Q&A',
    title: 'Ask a question',
    edit: 'Edit',
    delete: 'Delete',
    presenter: 'Presenter',
    reply_btn: 'Reply',
    edit_btn: 'Edit',
    cancel_btn: 'Cancel',
    submit_btn: 'Submit',
    textarea_message_placeholder: 'Your question',
    textarea_reply_placeholder: 'Your reply',
    textarea_placeholder: 'Ask a question',
    success_message: {
      added: 'Comment added',
      updated: 'Comment edited',
      deleted: 'Comment deleted',
      delete_all: 'The questions were successfully removed',
    },
    must_be_logged_in: 'You must be logged in to interact.',
    clear_messages: 'Clear all questions',
    clear_messages_confirm: 'Do you really want to clear all questions?',
  },
  live_chat: {
    title: 'Chat',
    textarea_placeholder: 'Type a message',
    reaction: 'React',
  },
  hints: {
    payment_void: 'Organizers permission only - not visible to participants.',
  },
  testimonials: [
    {
      quote:
        'I have been very impressed by the platform that meets the highest professional standards of academic events, and by the very personal support. I will undoubtedly use Fourwaves for our upcoming events.',
      name: 'Nathalie Grandvaux',
      title: 'Professor',
      organization_id: 'umontreal',
      organization_name: 'Université de Montréal',
    },
    {
      quote: `It's a wonderful tool, I don't know how managed to do it all without it! Fourwaves decreased my stress level by 85% for our events. I appreciate the availability and rapidness to answer our questions.`,
      name: 'Joanne Auclair',
      title: 'Administrative agent',
      organization_id: 'crchum',
      organization_name: 'Centre de recherche du CHUM',
    },
    {
      quote:
        'Fourwaves eliminates all irritants that any conference organizer will inevitably encounter, i.e. managing registration and payments, generating the final program and name tags, managing the event website, etc. This is such an essential platform!',
      name: 'Nicolas Doucet',
      title: 'Principal Investigator',
      organization_id: 'inrs',
      organization_name: 'Institut national de la recherche scientifique',
    },
    {
      quote:
        'We often use Fourwaves for our events. In addition to the ease of use, it is well adapted, efficient and above all very reliable. Fourwaves is the perfect tool to save time and succeed in organizing our events.',
      name: 'Pierre-Yves Savard',
      title: 'PROTEO Coordinator',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote: `I've been using Fourwaves for several years and I'm very satisfied. It greatly facilitates the registration process as well as the printing of badges and booklets. The team is very dynamic and technical support is always very fast.`,
      name: 'Annick Guyot',
      title: 'Research Associate',
      organization_id: 'mcgill',
      organization_name: 'McGill University',
    },
    {
      quote:
        'Great tool for organizing a scientific event! We did not need to set-up a website for our conference, everything is right here on Fourwaves, even the name-tag generator which was a lifesaver!',
      name: 'Pierre Lavigne',
      title: 'Researcher',
      organization_id: 'usherbrooke',
      organization_name: 'Université de Sherbrooke',
    },
    {
      quote:
        'Our event was a success thanks to the services of Fourwaves! The team is very responsive and available to help as needed and listen to improve their service. I recommend 100%!',
      name: 'Hermance Beaud',
      title: 'Research Support Officer',
      organization_id: 'uqam',
      organization_name: 'Université du Québec à Montréal',
    },
    {
      quote:
        'Our conference was a great success and it would not have been possible without the assistance of Fourwaves. The organizing committee strongly recommends Fourwaves to all those who are planning conferences.',
      name: 'Calvin Yip',
      title: 'Professor',
      organization_id: 'ubc',
      organization_name: 'The University of British Columbia',
    },
    {
      quote:
        'I planned a Symposium with the help of Fourwaves. They made it effortless. I highly recommend it for all of your planning needs.',
      name: 'Sylvie Lesage',
      title: 'Professor',
      organization_id: 'umontreal',
      organization_name: 'Université de Montréal',
    },
    {
      quote:
        'The platform is superb and made the Montérégie Cancer Network improve greatly on the efficiency and professionalism of its conferences.',
      name: 'Chantal Keough',
      title: 'Coordinator',
      organization_id: 'cisss',
      organization_name: `CIUSSS du Centre-Sud-de-l'Île-de-Montréal`,
    },
    {
      quote:
        'Our conference was a success, and Fourwaves really alleviated MANY headaches! We had an outstanding experience.',
      name: 'Rodrigo Fernandez-Gonzalez',
      title: 'Professor',
      organization_id: 'utoronto',
      organization_name: 'University of Toronto',
    },
    {
      quote: 'The registration process worked like a charm and Fourwaves saved me a lot of time over the past months!',
      name: 'Jean-Philippe Vézina',
      title: 'Professor',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote: `We recommend Fourwaves without hesitation, it's an exceptional platform.`,
      name: 'Chantale Bisson',
      title: 'Administrative Officer',
      organization_id: 'cdrv',
      organization_name: 'Centre de Recherche sur le Vieillissement',
    },
    {
      quote: 'Fourwaves saved us a lot of time. We will definitely continue to use it.',
      name: 'Myriam Talantikit',
      title: 'Administrative Officer',
      organization_id: 'rimuhc',
      organization_name: 'Research Institute of the McGill University Health Centre',
    },
    {
      quote: 'The platform is super easy to use and gives a professional look to our event!',
      name: 'Kim Santerre',
      title: 'PhD Student',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote:
        'An easy to use platform that saved us valuable time with registration and printing the proceedings. The platform adapted well to the specific needs of our scientific event. Congratulations and thanks for the amazing work!',
      name: 'Mylène Côté',
      title: 'Academic Coordinator',
      organization_id: 'usherbrooke',
      organization_name: 'Université de Sherbrooke',
    },
    {
      quote: `Fourwaves provides an efficient platform with a user-friendly interface that anyone can quickly learn how to use. For us, it was a lifesaver, it let us focus on all the other bigger issues involved in conference planning. Now I can't imagine organizing a conference without it!`,
      name: 'Lynn Kamerlin',
      title: 'Professor',
      organization_id: 'uppsala',
      organization_name: 'Uppsala University',
    },
    {
      quote:
        'Fourwaves enhanced the organization of our annual symposium at a professional level. The highly customizable interface saved us a lot of time. The support answered our questions promptly. Fourwaves was an essential tool for the success of our event.',
      name: 'Jean-François Sauvageau',
      title: 'Coordinator',
      organization_id: 'cbs',
      organization_name: 'Canadian Biomaterials Society',
    },
    {
      quote:
        '3DSIG is the largest international gathering on structural computational biology with renowned speakers including Nobel prize winners. Organizing such a meeting was made considerably easier with Fourwaves. I can only praise Fourwaves for their professionalism in developing this turn-key solution for conference management that allows academics to focus on the science of the event. I definitely encourage you to use Fourwaves.',
      name: 'Rafael Najmanovich',
      title: 'Professor',
      organization_id: 'umontreal',
      organization_name: 'Université de Montréal',
    },
    {
      quote:
        'Fourwaves is the ideal platform to help support event organizers. Much appreciated by participants, it saved us a lot of time for the Journée Phare 2012, 2013. The team was very responsive to our needs. Very flexible and adaptable!',
      name: 'Marc-Olivier Frégeau',
      title: 'President',
      organization_id: 'phare',
      organization_name: 'Journée Phare',
    },
    {
      quote:
        'I have only good comments, the platform is easy to use, hyper functional, everything is there! I had the impression that Fourwaves was built according to my own specifications.',
      name: 'Ninon Cardinal',
      title: 'President',
      organization_id: 'safran',
      organization_name: 'Safran Événements',
    },
    {
      quote:
        'Fourwaves was amazing to help manage abstract submissions, registrations, the booklet, name tags and to promote the event. It helped us be very efficient in our communications.',
      name: 'Claudia Larochelle',
      title: 'Research professional',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote: `Registration was very smooth - we didn't have to do anything at all once it was set up. We are quite happy with how it worked out.`,
      name: 'Natalie Goto',
      title: 'Professor',
      organization_id: 'uottawa',
      organization_name: 'University of Ottawa',
    },
    {
      quote: 'I loved working with Fourwaves, everything went well for the registrations and to work with the lists.',
      name: 'Annie Caisse',
      title: 'Administrative Agent',
      organization_id: 'chusj',
      organization_name: 'CHU Sainte-Justine',
    },
    {
      quote:
        'Using Fourwaves for the management of scientific events, big or small is now essential for our organization. The interface is very easy to learn, customizable and complete. The entire Fourwaves team offers incredible service!',
      name: 'Aline Dumas',
      title: 'Events coordinator',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote:
        'The platform is user-friendly and easy to use. The Fourwaves team also offers unparalleled support. They are responsive and attentive, which greatly facilitates our work as an organizer!',
      name: 'Fanny Toussaint',
      title: 'Program Manager',
      organization_id: 'rimuhc',
      organization_name: 'Research Institute of the McGill University Health Centre',
    },
    {
      quote:
        'The event went really well. We did not experience any technological difficulties either on the registration side or on the videoconferencing side of the event. We really enjoyed the platform.',
      name: 'Rosalie Kott',
      title: 'Lawyer',
      organization_id: 'cisss',
      organization_name: `CIUSSS du Centre-Sud-de-l'Île-de-Montréal`,
    },
    {
      quote:
        'The Fourwaves platform worked very well for the meeting, and everyone seemed to be able to access it with ease.',
      name: 'Daphne Goring',
      title: 'Professor',
      organization_id: 'utoronto',
      organization_name: 'University of Toronto',
    },
    {
      quote: `Fourwaves' help was invaluable. It makes all the difference in organizing a virtual event. We received feedback from participants who greatly appreciated the format and the platform.`,
      name: 'Chantal Binda',
      title: 'Academic coordinator',
      organization_id: 'usherbrooke',
      organization_name: 'Université de Sherbrooke',
    },
    {
      quote:
        'Our annual virtual day was a great success. Participants liked the opportunity of live video conversations with students in front of their posters. I received only good comments on Fourwaves.',
      name: 'Friederike Pfau',
      title: 'Project Manager',
      organization_id: 'chuqul',
      organization_name: 'CHU de Québec-Université Laval',
    },
    {
      quote:
        'In summary: I loved it !! The Fourwaves virtual poster session is an A1 alternative when face-to-face is not possible. I loved the interactions. I was able to talk to all the poster presenters easily from home.',
      name: 'Valérie Lavastre',
      title: 'Coordinator',
      organization_id: 'rrsv',
      organization_name: 'Vision Health Research Network ',
    },
    {
      quote:
        'Fourwaves undeniably contributed to the great success of our Colloquium, allowing us to offer all of our participants an interactive event of professional quality, at a more than affordable price.',
      name: 'Meghan Bergeron',
      title: 'Student',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote: `It's one thing to sell a solution and a service; it's another to keep customers happy. I can say that Fourwaves rose to the challenge with excellence!`,
      name: 'Martin Guay',
      title: 'Communications advisor',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote:
        'We really enjoyed the use of Fourwaves. Thank you so much for providing this platform, it was truly a highlight of our event.',
      name: 'Amber Leland',
      title: 'Assistant Director',
      organization_id: 'crcao',
      organization_name: 'Coordinating Research Council',
    },
    {
      quote: 'It went really well, the participants really appreciated the platform and its features!',
      name: 'Sophie Aubé',
      title: 'Teaching Assistant',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote:
        'The virtual poster hall with video conferencing helped us keep the spirit of the event and provided the opportunity for many conversations between the participants and presenters.',
      name: 'David Bird',
      title: 'Associate Professor',
      organization_id: 'mtroyal',
      organization_name: 'Mount Royal University',
    },
    {
      quote: 'The event went really well and people really seemed to enjoy using the Fourwaves platform.',
      name: 'Anna Duron',
      title: 'Program Assistant',
      organization_id: 'uidaho',
      organization_name: 'University of Idaho',
    },
    {
      quote:
        'The highlight was the ability to host live discussions in a virtual room during the poster session. The event site worked extremely well pre, during and post poster session. Thank you for your strong customer support.',
      name: 'Emily Delgado',
      title: 'Senior Manager',
      organization_id: 'mskcc',
      organization_name: 'Memorial Sloan Kettering Cancer Center',
    },
    {
      quote:
        'Our event went very well, both for the conferences and the poster session. Thank you very much and well done for your improved platform!',
      name: 'Andree Lessard',
      title: 'Network coordinator',
      organization_id: 'mcgill',
      organization_name: 'McGill University',
    },
    {
      quote: `Fourwaves is such a great platform. I used it for 2 events and both were so easy and I've heard lots of compliments from others, as well.`,
      name: 'Emilie Brown',
      title: 'Coordinator',
      organization_id: 'ysu',
      organization_name: 'Youngstown State University',
    },
    {
      quote: 'The conference went very well and everyone was happy with the platform. Thank you !',
      name: 'Cécile Berne',
      title: 'Research Associate',
      organization_id: 'umontreal',
      organization_name: 'Université de Montréal',
    },
    {
      quote:
        'Thanks to Fourwaves, we organized our doctoral student day, with two virtual poster sessions. The discussions at posters were greatly appreciated by the doctoral students, we thank Fourwaves!',
      name: 'Marie-Hélène Berger',
      title: 'Research director',
      organization_id: 'mpt',
      organization_name: 'Mines ParisTech',
    },
    {
      quote:
        'I really appreciate the responsiveness and efficiency of the team. The platform works great, thank you again.',
      name: 'Evelyne Muhire',
      title: 'Coordinator',
      organization_id: 'umontreal',
      organization_name: 'Université de Montréal',
    },
    {
      quote:
        'I wanted to thank Fourwaves for the impeccable and personalized service we received. The platform and the clear and quick responses from the team members certainly contributed to the success of our event.',
      name: 'Pauline Claude',
      title: 'Research professional',
      organization_id: 'crispesh',
      organization_name: 'CRISPESH',
    },
    {
      quote:
        'I really liked the experience on Fourwaves for the 42nd Symposium online event. I had good feedback from the speakers and participants.',
      name: 'Kapinga Kalala',
      title: 'Coordinator',
      organization_id: 'umontreal',
      organization_name: 'Université de Montréal',
    },
    {
      quote:
        'Fourwaves was great for our Research Days at the Faculty of Medicine and affiliated research centers of Université Laval. The personalized approach and listening from Fourwaves are added values.',
      name: 'Louise Laperrière',
      title: 'Assistant to the Vice-Dean',
      organization_id: 'ulaval',
      organization_name: 'Université Laval',
    },
    {
      quote: `Convenient. Straight-forward. Professional As the event coordinator, I have trust in the functionality and professionalism of the platform. Every time, our guests report that it's intuitive and easy to navigate. Additionally, the Fourwaves staff have always been very courteous, helpful, and responsive.`,
      name: 'Brieanna Wright',
      title: 'Coordinator',
      organization_id: 'lbl',
      organization_name: 'Berkeley Lab',
    },
  ],
});
