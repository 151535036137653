import { FormFieldDto } from '../services';

type Figure = {
  id: string;
  url: string;
  title: string;
  description: string;
};

type MediaEntry<T> = { value: T; field: FormFieldDto };

export interface PresentationMedia {
  poster: MediaEntry<string> | null;
  video: MediaEntry<string> | null;
  slides: MediaEntry<string> | null;
  figures: MediaEntry<Figure[]> | null;
}

export enum ListingOptions {
  Numerical = 'numerical',
  Alphabetical = 'alphabetical',
  Upvotes = 'upvotes',
}

export enum OrderingQueryFilters {
  SequentialId = 'SequentialId',
  UpvoteCount = 'UpvoteCount',
}
