
import { Component, Vue, Watch, Ref } from 'nuxt-property-decorator';
import Modal from './Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class BeforeUnloadModal extends Vue {
  @Ref() modal?: Modal;

  @Watch('$beforeUnload.isModalVisible')
  public onBeforeUnloadModalVisibilityChange(isVisible: boolean, wasVisible: boolean) {
    if (isVisible && !wasVisible) this.modal?.triggerVisibility();
  }

  public onConfirm() {
    this.$beforeUnload.resolutionHandlers?.confirm();
    this.modal?.triggerVisibility();
  }

  public onCancel() {
    this.$beforeUnload.resolutionHandlers?.cancel();
  }
}
