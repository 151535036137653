
import { mixins, Component, Watch, Prop, Ref } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { castQueryArrays } from '../../helpers';
import { Collapsible } from '../layout';
import { GlobalFilterEvents, InputSelectOption } from '../../types';
import Badge from '../Badge.vue';
import FilterMixin from './FilterMixin';

@Component({
  components: {
    Collapsible,
    Badge,
  },
})
export default class FilterMultiSelect extends mixins(FilterMixin) {
  @Prop({ default: Array }) readonly options!: InputSelectOption[];
  @Prop({ default: '' }) readonly label!: string;
  @Prop(Boolean) readonly isMobile!: boolean;

  @Ref() readonly optionsMenu?: HTMLMenuElement;

  isOpen = this.isMobile;
  hasFocus = false;
  GlobalFilterEvents = GlobalFilterEvents;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;

  get optionsIdList() {
    return this.options.map(({ id }) => String(id));
  }

  get selected(): string[] {
    if (!this.$route.query[this.queryKey]) return [];
    const query = castQueryArrays(this.$route.query, [this.queryKey]);
    return query[this.queryKey] as string[];
  }

  get ownSelected() {
    return this.selected.filter(id => this.optionsIdList.includes(id));
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', this.closeMenu);
      this.$nuxt.$on(GlobalFilterEvents.SelectOpen, this.closeMenu);
    } else {
      document.body.removeEventListener('click', this.closeMenu);
      this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeMenu);
    }
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenu) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenu);
    await this.$nextTick();
    this.scrollElement = this.simplebarInstance.getScrollElement();
    this.optionsMenu?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.closeMenu);
    this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeMenu);
  }

  public toggleSelection(id: string | number) {
    const normalizedId = String(id);
    const selection = this.selected.includes(normalizedId)
      ? this.selected.filter(selectionId => selectionId !== normalizedId)
      : [...this.selected, normalizedId];

    this.updateQuery(selection, !selection.length);
  }

  public clearOwnSelection() {
    const newSelection = this.selected.filter(id => !this.optionsIdList.includes(id));
    this.updateQuery(newSelection, !newSelection.length);
  }

  public isOptionSelected(id: string | number) {
    return this.selected.includes(String(id));
  }

  public onClickLabel() {
    this.isOpen = !this.isOpen;
    this.$nuxt.$emit(GlobalFilterEvents.SelectOpen);
  }

  public closeMenu() {
    this.isOpen = false;
  }

  public onMenuClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }
}
