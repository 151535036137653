var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.user)?_c('div',[(_vm.user.bio)?_c('section',{staticClass:"participant__section"},[_c('div',{staticClass:"participant__section-header"},[_c('h2',{staticClass:"participant__section-title"},[_vm._v("\n        "+_vm._s(_vm.$t('shared.participants.about'))+"\n      ")]),_vm._v(" "),_c('Icon',{staticClass:"participant__waves",attrs:{"name":"wave"}})],1),_vm._v(" "),_c('div',{staticClass:"participant__bio",domProps:{"innerHTML":_vm._s(_vm.getCKEditorContent(_vm.user.bio))}})]):_vm._e(),_vm._v(" "),(_vm.presentations.length > 0)?_c('section',{staticClass:"participant__section"},[_c('div',{staticClass:"participant__section-header"},[_c('h2',{staticClass:"participant__section-title"},[_vm._v(_vm._s(_vm.$t('shared.participants.presentations.title')))]),_vm._v(" "),_c('Icon',{staticClass:"participant__waves",attrs:{"name":"wave"}})],1),_vm._v(" "),_vm._l((_vm.presentations),function(presentation){return _c('PresentationCard',_vm._b({key:presentation.id,staticClass:"timeline__card",attrs:{"form":_vm.submissionForm,"layout-mode":_vm.LayoutModes.list},on:{"toggle-vote":function($event){return _vm.$emit('toggle-vote', presentation)},"toggle-bookmark":function($event){return _vm.$emit('toggle-bookmark', presentation)}}},'PresentationCard',{
        presentation,
        isParticipant: _vm.isParticipant,
        isOrganizer: _vm.isOrganizer,
        isVotingEnabled: _vm.isVotingEnabled,
        linkName: _vm.linkName,
      },false))})],2):_vm._e(),_vm._v(" "),_c('ParticipantOtherInformations',_vm._b({staticClass:"participant__section"},'ParticipantOtherInformations',{ form: _vm.form, formEntry: _vm.formEntry, pageType: _vm.pageType },false))],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }