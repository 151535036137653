import { MutationTree, ActionTree } from 'vuex';
import {
  EventDto,
  PageDto,
  PageType,
  toPlainString,
  FeatureDto,
  ScheduleDto,
  getters as eventsGetters,
  RoomDto,
  TrackDto,
  moment,
} from 'fourwaves-shared';
import { eventsMutations } from 'fourwaves-shared/src/store/events/mutations';
import { RootState, EventsState } from '~/types';

export const state = (): EventsState => ({
  eventId: '',
  language: 'browser',
  currentEvent: undefined,
  pages: [],
  sessions: [],
  tracks: [],
  rooms: [],
  currency: 'CAD',
  currentTime: '',
  isWhiteLabel: false,
  isRegistrationCapacityLimited: false,
  withUnlimitedSubmissions: false,
  isVotingEnabled: true,
  isReviewingFeatureEnabled: false,
  withLiveApp: false,
});

export const getters = {
  ...eventsGetters,
  currentEvent: (state: EventsState): EventDto => {
    return state.currentEvent!;
  },

  isLiveAppOpen: (state: EventsState): boolean => {
    if (!state.withLiveApp) return false;
    const now = moment(state.currentTime);
    const startDate = moment(state.currentEvent?.startDate).startOf('day');
    const endDate = moment(state.currentEvent?.endDate).endOf('day');
    const isEventOngoing = state.currentEvent ? now.isSameOrAfter(startDate) && endDate.isSameOrAfter(now) : false;
    return isEventOngoing;
  },

  // Pages
  registrationPage: (state: EventsState): PageDto | undefined => {
    return state.pages.find(x => x.type === PageType.Registration);
  },
  submissionPage: (state: EventsState): PageDto | undefined => {
    return state.pages.find(x => x.type === PageType.Submission);
  },
  schedulePage: (state: EventsState): PageDto | undefined => {
    return state.pages.find(x => x.type === PageType.Schedule);
  },
};

export const mutations: MutationTree<EventsState> = {
  ...eventsMutations,
  setEventId(state: EventsState, eventId: string): void {
    state.eventId = eventId;
  },
  setCurrentEvent(state: EventsState, dto: EventDto): void {
    state.currentEvent = dto;
    state.currency = dto.currency;
  },
  setLanguage(state: EventsState, language: string): void {
    state.language = language;
  },
  setPages(state: EventsState, pages: PageDto[] | null): void {
    state.pages = pages || [];
  },
  setSessions(state: EventsState, sessions: ScheduleDto[] | null): void {
    state.sessions = sessions || [];
  },
  setRooms(state: EventsState, rooms: RoomDto[] | null): void {
    state.rooms = rooms || [];
  },
  setFeatures(state: EventsState, features: FeatureDto[] | null): void {
    if (features) {
      state.isWhiteLabel = features.find(x => x.name === 'white_label')!.isEnabled;
      state.isRegistrationCapacityLimited = features.find(x => x.name === 'reg_capacity_limited')!.isEnabled;
      state.withUnlimitedSubmissions = features.find(x => x.name === 'with_unlimited_submissions')!.isEnabled;
      state.isVotingEnabled = features.find(x => x.name === 'voting_enabled')!.isEnabled;
      state.isReviewingFeatureEnabled = features.find(x => x.name === 'reviewing')!.isEnabled;
      state.withLiveApp = features.find(x => x.name === 'with_live_app')!.isEnabled;
    }
  },
  setTracks(state: EventsState, tracks: TrackDto[]): void {
    state.tracks = tracks || [];
  },
  updateTime(state: EventsState): void {
    state.currentTime = toPlainString(moment.utc());
  },
};

export const actions: ActionTree<EventsState, RootState> = {
  async init(_, slug): Promise<void> {
    await this.dispatch('events/startTimeUpdates');
    return await this.dispatch('events/setEventContext', slug);
  },
  async fetchEvent(context): Promise<EventDto> {
    const event = await this.$api.getEvent(context.state.eventId);
    context.commit('setCurrentEvent', event);
    return event;
  },
  async setEventContext({ commit }, eventId: string): Promise<boolean> {
    this.$api._client.defaults.baseURL = this.$config.API_URL;
    if (
      !process.client &&
      this.$config.API_URL.includes('localhost') &&
      process.env.DATABASE_CONNECTION?.includes('pgsql_datastore')
    ) {
      this.$api._client.defaults.baseURL = 'http://fourwaves_platform:8080';
    }

    const authToken = this.$cookies.get('auth._token.local');
    if (authToken !== undefined) {
      this.$api._client.defaults.headers.common.Authorization = this.$cookies.get('auth._token.local');
    }

    const event = await this.$api.getEvent(eventId);
    if (event) {
      commit('setEventId', eventId);
      commit('setCurrentEvent', event);
      commit('setLanguage', event.language);

      const [pages, sessions, tracks, rooms, features] = await Promise.all([
        this.$api.getPages(event.id, false),
        this.$api.getSessions(event.id),
        this.$api.getTracks(event.id),
        this.$api.getRooms(event.id),
        this.$api.getFeatures(event.id),
      ]);

      commit('setPages', pages);
      commit('setSessions', sessions);
      commit('setTracks', tracks);
      commit('setRooms', rooms);
      commit('setFeatures', features);

      return true;
    } else {
      // The event does not exist anymore, or the user is not permitted.
      return false;
    }
  },
  startTimeUpdates({ commit }): void {
    commit('updateTime');
    setInterval(() => commit('updateTime'), 1000 * 10);
  },
};
