
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { ProgressStep } from '../../../types';
import { rem } from '../../../helpers';

@Component({})
export default class ProgressSteps extends Vue {
  @Prop({ type: Array, required: true }) readonly steps!: ProgressStep[];
  @Prop({ type: Number, required: true }) readonly currentStep!: number;
  @Prop({ type: String, default: 'sm' }) readonly size!: string;
  @Prop(Boolean) readonly vertical!: boolean;

  get stepsCount() {
    return this.steps.length;
  }

  get isHorizontal() {
    return !this.vertical && this.$device.tablet;
  }

  get sizeMap() {
    return {
      sm: {
        '--pin-size': rem(24),
        '--item-gap': rem(12),
        '--font-size': rem(14),
        '--dot-size': rem(8),
        '--icon-size': rem(12),
      },
      md: {
        '--pin-size': rem(32),
        '--item-gap': rem(16),
        '--font-size': rem(16),
        '--dot-size': rem(10),
        '--icon-size': rem(16),
      },
      lg: {
        '--pin-size': rem(40),
        '--item-gap': rem(16),
        '--font-size': rem(16),
        '--dot-size': rem(12),
        '--icon-size': rem(18),
      },
    };
  }

  get sizingProperties() {
    return { ...this.sizeMap[this.size], '--steps-count': this.stepsCount };
  }

  public getStepModifier(index: number) {
    return {
      '-active': index === this.currentStep,
      '-completed': index < this.currentStep,
    };
  }
}
