
import { Component, Vue, Prop, Ref } from 'nuxt-property-decorator';
import { TranslateResult } from 'vue-i18n';
import InputText from '../design-system/input/input-text/InputText.vue';
import Modal from './Modal.vue';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  components: { Modal, InputText },
})
export default class DeleteModal extends Vue {
  @Prop({ type: String, default: null }) readonly item!: TranslateResult;
  @Prop({ type: String, default: null }) readonly subtitle!: TranslateResult | null;
  @Prop({ type: String, default: null }) readonly cascadeEffect!: TranslateResult | null;
  @Prop(Boolean) readonly safe!: boolean;
  @Prop(Boolean) readonly isWaiting!: boolean;
  @Ref() modal?: Modal;

  confirmation = '';
  itemName: TranslateResult = '';

  get modalSubtitle() {
    if (this.subtitle) return this.subtitle;
    return this.cascadeEffect
      ? this.$t('shared.modal.delete.cascade_notice', { cascade: this.cascadeEffect })
      : this.$t('shared.modal.delete.notice', { item: this.itemName });
  }

  public async handleDelete() {
    if (!(await this.$validator.validateAll())) return;
    this.$emit('delete', this.confirmation);
  }

  public triggerVisibility(event?: PointerEvent, item?: TranslateResult) {
    this.confirmation = '';
    this.itemName = item || this.item;
    this.modal?.triggerVisibility(event?.currentTarget);
  }
}
