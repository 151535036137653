
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import PresentationCard from '../presentations/PresentationCard.vue';
import { PresentationElement } from '../presentations';
import { PresentationTime, PresentationTimesType, FormDto } from '../../services';
import { LayoutModes } from '../../types';

@Component({
  components: {
    PresentationCard,
  },
})
export default class SessionPresentationsList extends Vue {
  @Prop({ required: true }) currentTime!: string;
  @Prop({ required: true }) presentations!: PresentationElement[];
  @Prop({ required: true }) presentationTimesType!: PresentationTimesType;
  @Prop({ required: true }) readonly linkName!: string;
  @Prop({ required: true }) readonly submissionForm!: FormDto | null;
  @Prop(Boolean) readonly isParticipant!: boolean;
  @Prop(Boolean) readonly isOrganizer!: boolean;
  @Prop(Boolean) readonly isVpsOpen!: boolean;
  @Prop(Boolean) readonly isVotingEnabled!: boolean;
  @Prop(Boolean) isLiveApp!: boolean;

  readonly LayoutModes = LayoutModes;
  readonly PresentationTimesType = PresentationTimesType;

  public getTimeFromTimeGroup(timeGroup?: PresentationTime): string {
    if (!timeGroup) return '';
    return this.$moment(timeGroup.start).format('LT');
  }

  public getPresentationTimeNotice(time: PresentationTime): string {
    const startHour = this.$moment(time.start).format('LT');
    const endHour = this.$moment(time.end).format('LT');
    return `${startHour} - ${endHour}`;
  }

  public getHideConnectionCount(presentation): boolean {
    if (!this.isLiveApp) return true;
    return !this.isVpsOpen || !presentation.isDiscussionPermittedBySession;
  }
}
