import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Size, Alignment } from '../../types';
import { rem } from '../../helpers';

@Component
export default class ActionMixin extends Vue {
  @Prop({ type: String, default: 'primary' }) readonly hierarchy!: string;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop({ type: String, default: 'center' }) readonly alignment!: string;
  @Prop(String) readonly iconLeading!: string;
  @Prop(String) readonly iconTrailing!: string;
  @Prop(Boolean) readonly dark!: boolean;
  @Prop(Boolean) readonly destructive!: boolean;
  @Prop(Boolean) readonly highlight!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;

  Sizes = Size;

  isIconOnly = false;

  get sizeMap() {
    return this.isIconOnly
      ? {
          sm: {
            '--height': rem(36),
            '--width': rem(36),
            '--icon-size': rem(20),
            '--padding': 0,
          },
          md: {
            '--height': rem(44),
            '--width': rem(44),
            '--icon-size': rem(20),
            '--padding': 0,
          },
          lg: {
            '--height': rem(56),
            '--width': rem(56),
            '--icon-size': rem(24),
            '--padding': 0,
          },
        }
      : {
          sm: {
            '--height': rem(36),
            '--icon-size': rem(16),
            '--padding': rem(12),
          },
          md: {
            '--height': rem(44),
            '--icon-size': rem(20),
            '--padding': rem(16),
          },
          lg: {
            '--height': rem(60),
            '--icon-size': rem(24),
            '--padding': rem(24),
          },
        };
  }

  get isLink() {
    return /^link-/.test(this.hierarchy);
  }

  get isLabeledLink() {
    return this.isLink && !this.isIconOnly;
  }

  get elementClassNames() {
    return {
      '-dark': this.dark,
      '-destructive': this.destructive,
      '-highlight': this.highlight,
      '-hit-area': this.isLink && this.isIconOnly,
      '-icon-margin': !this.isIconOnly,
      '-transform-scale -no-wrap -line-height': !this.isLabeledLink,
      '-word-break': this.isLabeledLink,
      '-align-left': this.isLabeledLink || this.alignment === Alignment.Start,
      '-pointer-none': this.disabled,
      '-border-radius': !this.isLink,
    };
  }

  get sizingProperties() {
    if (this.isLink && this.isIconOnly) {
      return { ...this.sizeMap[this.size], '--padding': 0, '--height': 'auto', '--width': 'auto' };
    }
    return this.isLabeledLink
      ? { ...this.sizeMap[this.size], '--padding': 0, '--height': 'auto' }
      : this.sizeMap[this.size];
  }

  created() {
    this.detectSlotContent();
  }

  beforeUpdate() {
    this.detectSlotContent();
  }

  public detectSlotContent() {
    this.isIconOnly = !this.$slots.default?.length;
  }
}
