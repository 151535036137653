export interface CivicAddressDto {
  addressLine1: string;
  city: string;
  countryRegion: string;
  stateProvince: string;
  postalCode: string;
}

export interface BillingInfoDto {
  firstName: string;
  lastName: string;
  company?: string;
  email: string;
  vatNumber?: string;
  civicAddress: CivicAddressDto;
}

export function format(info: BillingInfoDto) {
  if (!info) return '---';

  let address = `${info.firstName} ${info.lastName},`;
  if (info.company) address = `${address} ${info.company},`;
  address = `${address} ${info.civicAddress.addressLine1}, ${info.civicAddress.city},`;
  address = `${address} ${info.civicAddress.stateProvince} (${info.civicAddress.postalCode}),`;
  address = `${address} ${info.civicAddress.countryRegion}`;

  if (info.vatNumber) address = `${address}, VAT: ${info.vatNumber}`;

  return address;
}
