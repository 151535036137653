import { ExtendOptions, MessageGenerator, RuleValidate } from 'vee-validate';
import { AuthorDto } from 'fourwaves-shared/src/services';
import { Dictionary } from '../core';
import { getWordCount } from '../helpers/string';

export type CustomValidationRule = {
  validate: RuleValidate;
  localize?: { fr: MessageGenerator; en: MessageGenerator };
  options?: ExtendOptions;
};

export const validationRules: Dictionary<CustomValidationRule> = {
  size_mb: {
    validate: (files, [size]: any) => {
      if (isNaN(size)) return false;
      const nSize = Number(size) * 1e6; // MB
      return files instanceof FileList ? Array.from(files).every(file => file.size <= nSize) : true;
    },
    localize: {
      fr: () => 'Ce fichier est trop lourd.',
      en: () => 'This file is too heavy.',
    },
  },
  confirm_email: {
    validate: (value, [email]: any) => value.toLowerCase() === email.toLowerCase(),
    localize: {
      fr: () => 'Les deux courriels doivent être identiques.',
      en: () => 'Both emails must be identical.',
    },
  },
  minimum_one_presenter: {
    validate: (value: AuthorDto[]) => {
      if (value && Array.isArray(value) && value.some(x => x.isPresenter)) {
        return true;
      }
      return false;
    },
    localize: {
      fr: () => 'Vous devez avoir au moins un auteur qui est présentateur.',
      en: () => 'You must have at least one author who is a presenter.',
    },
  },
  duplicate_author_email: {
    validate: (value: AuthorDto[]) => {
      if (value && Array.isArray(value)) {
        const emails = value.filter(x => x.email).map(x => x.email.trim().toLowerCase());
        return new Set(emails).size === emails.length;
      }
      return true;
    },
  },

  word_limit: {
    validate: (value, meta: any = {}) => {
      const { maxWords = 0 } = meta;
      const wordCount = getWordCount(value || '');
      return maxWords === 0 || wordCount <= maxWords;
    },
    options: { paramNames: ['maxWords'] },
    localize: {
      fr: () => "Vous avez entré trop de mots. Veuillez raccourcir l'entrée.",
      en: () => 'You entered too many words. Please shorten the input.',
    },
  },
};
