import Vue from 'vue';
import {
  TranslatableField,
  transl8,
  generateUserFriendlyId,
  moneyMixins,
  dateMixins,
  addressMixins,
  PageDto,
  PageType,
  validatorMixins,
  formEntryMixins,
  contentMixins,
  authMixins,
  validationMixins,
  PossibleDate,
  fileMixins,
} from 'fourwaves-shared';

export const mixinMethods = {
  // Shared mixins
  ...formEntryMixins,
  ...dateMixins,
  ...addressMixins,
  ...validatorMixins,
  ...moneyMixins,
  ...contentMixins,
  ...authMixins,
  ...validationMixins,
  ...fileMixins,
  getUserFriendlyId: generateUserFriendlyId,

  // Event website mixins
  showUserFriendlyDate: function (date?: PossibleDate): string {
    return date ? this.$moment(new Date(date)).format('LL') : '---';
  },
  transl8: function (field: TranslatableField): string {
    return transl8(field, this.$i18n);
  },
  scrollToErrorsOrContainer: function (): void {
    this.$nextTick(() => {
      if (this.$validator && this.$validator.errors.items.length > 0) {
        this.$scrollTo(`.js-validation-error:not(:empty)`, { offset: -400 });
      } else {
        this.$scrollTo(`.container`, {});
      }
    });
  },
  buildPageLink: function (page: PageDto): string {
    switch (page.type) {
      case PageType.Registration:
        return this.localePath({ name: 'slug-registration' });

      case PageType.Submission:
        return this.localePath({ name: 'slug-submission' });

      case PageType.Abstracts:
        return this.localePath({ name: 'slug-abstracts' });

      case PageType.Participants:
        return this.localePath({ name: 'slug-participants' });

      case PageType.Schedule:
        return this.localePath({ name: 'slug-schedule' });

      case PageType.Home:
        return this.localePath({ name: 'slug-pages-id' });

      default:
        return this.localePath({ name: 'slug-pages-id', params: { id: page.id } });
    }
  },
};

Vue.mixin({
  methods: mixinMethods,
});
