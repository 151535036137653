import { FormFieldDto } from './FormDto';
import { TranslatableField } from './TranslatableField';
import { UserDto } from './UserDto';
import { CivicAddressDto } from './BillingInfoDto';

export enum BankAccountConnectionState {
  Accepted = 'accepted',
  Waiting = 'waiting',
  Refused = 'refused',
  CorrectionRequired = 'correctionrequired',
}

export enum PaymentGateway {
  Stripe = 'stripe',
  GlobalPayments = 'globalpayments',
  TouchNet = 'touchnet',
  Moneris = 'moneris',
}
export interface OrganizationDto {
  id: string;
  name: TranslatableField;
  civicAddress: CivicAddressDto | null;
}

export interface OrganizationBankAccountDto {
  id: string;
  organizationId: string;
  formId: string;
  name: TranslatableField;
  code: string;
  currency: string;
  receiptHeader: TranslatableField;
  gstBusinessNumber: string;
  qstBusinessNumber: string;
  vatBusinessNumber: string;
  pstBusinessNumber: string;

  acceptedConnectionsCount?: number;
  waitingConnectionsCount?: number;
  refusedConnectionsCount?: number;
  correctionRequiredConnectionsCount?: number;

  stripePublishableKey: string | null;
  paysafePublishableKey: string | null;
  globalPaymentsAppId: string;

  touchNetTLinkUrl: string;
  touchNetUPayUrl: string;
  touchNetUPaySiteId: number | null;

  monerisStoreId: string;
  monerisTokenizationId: string;
}

export interface OrganizationBankAccountConnectionDto {
  id: string;
  formEntryId: string;
  organizationId: string;
  organizationName: TranslatableField;
  organizationBankAccountId: string;
  organizationBankAccountName: TranslatableField;
  eventId: string;
  eventName: TranslatableField;
  requester: UserDto;
  decisionDate: string | null;
  decisionUser: UserDto | null;
  state: BankAccountConnectionState;
  notes: string;
  creationDate: string;
  eventCosts: FormFieldDto[];
}

export interface UpdateOrganizationModel {
  id: string;
  name: string;
  civicAddress: CivicAddressDto;
}

export interface OrganizationBillingInfoDto {
  name: string;
  civicAddress: CivicAddressDto;
  purchaseOrderNumber: string | null;
}
