export default {
  save: 'Save',
  cancel: 'Cancel',
  next: 'Next',
  prev: 'Back',
  step: 'Step',
  delete: {
    title: 'Delete {item}',
    notice: 'Are you sure you want to delete {item}?',
    cascade_notice: 'This will also affect {cascade}. This action is permanent and cannot be undone.',
    input_label: 'Type DELETE to confirm deletion',
    action: 'Delete',
  },
  beforeUnload: {
    title: 'Unsaved Changes',
    notice: 'Are you sure you want to leave this page? Changes will be lost.',
    confirm: 'Leave Page',
    cancel: 'Cancel',
  },
  saving: 'Saving...',
  deleting: 'Deleting...',
  removing: 'Removing...',
  confirming: 'Confirming...',
  sending: 'Sending...',
  adding: 'Adding...',
};
