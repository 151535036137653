export default {
  bookmark_filter: 'Mes favoris',
  bookmark: 'Favori',
  bookmarked: 'Favori',
  comment: '{count} commentaires | {count} commentaire | {count} commentaires',
  contact: 'Contacter',
  close: 'Fermer la visionneuse multimédia',
  disable_laser: 'Désactiver le pointeur laser',
  enable_laser: 'Activer le pointeur laser',
  follow: 'Suivre',
  following: 'En suivi de',
  list_layout: 'Vue en mode liste',
  no_presentations: 'Aucune présentation',
  no_results: 'Aucune présentation ne correspond à votre recherche.',
  other_information: 'Autres informations',
  grid_layout: 'Vue en grille',
  preparing_poster: `Chargement de l'affiche`,
  presentation: 'Aucune présentation | {count} présentation | {count} présentations',
  presentation_aria_label: 'Voir la présentation {title}',
  presented_by: 'Présenté par ',
  presenting_now: 'Présentation en cours',
  review_status_title: 'À évaluer',
  schedule_filter: 'Session',
  total_count: 'présentation | présentations',
  upvote: 'vote | vote | votes',
  unvote: 'dévote',
  poster: 'Affiche',
  video: 'Vidéo',
  slides: 'Diapositives',
  figures: 'Figures',
  view: 'Voir la présentation',
  details: 'Détails',
  contact_presenter_modal: {
    title: 'Contacter le présentateur',
    send_email: 'Envoyer le courriel',
    success_message: 'Courriel envoyé.',
  },
  listing_options: {
    alphabetical: 'A - Z',
    numerical: '1 - 9',
    upvotes: 'Top voted',
  },
  schedule_presentations: {
    description: "* Toutes les heures indiquées sont basées sur l'heure",
    title: 'Programmé dans {count} session | Programmé dans {count} sessions',
  },
  search_placeholder: 'Rechercher par titre, résumé ou auteur',
  see_all: 'Voir toutes les présentations',
};
