export function getCKEditorContent(content: string, isTargetBlank = true) {
  if (content && content.length > 0) {
    // weird layout bug on mobile
    content = content.replace(/&nbsp;/g, ' ');

    if (isTargetBlank) {
      content = content.replace(/href=/g, 'target="_blank" href=');
    }

    // link parsing
    const regex = /href=".*?"/g;
    let regexMatch;
    while ((regexMatch = regex.exec(content)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (regexMatch.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      regexMatch.forEach(match => {
        const url: string = match.replace('href="', '').replace('"', '');
        if (
          !url.startsWith('http://') &&
          !url.startsWith('https://') &&
          !url.startsWith('#') &&
          !url.startsWith('mailto:') &&
          !url.startsWith('file:') &&
          url.length !== 0
        ) {
          const newHref = url.replace(url, `http://${url}`);
          content = content.replace(match, match.replace(url, newHref));
        }
      });
    }
  }
  return content;
}
