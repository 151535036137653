import { isUtcNowInRange } from '../../../helpers';
import type { TranslatableField } from './TranslatableField';
import { formatMoney } from './TransactionDto';

export enum TaxRules {
  None = 'none',
  QstAllGstAll = 'qstallgstall',
  QstQuebecGstCanada = 'qstquebecgstcanada',
  QstQuebecGstAll = 'qstquebecgstall',
  GstAll = 'gstall',
  Gst5Canada = 'gst5canada',
  Gst5Pst6 = 'gst5pst6',
  Gst5Pst7 = 'gst5pst7',
  Gst10All = 'gst10all',
  Gst15All = 'gst15all',
  Hst13All = 'hst13all',
  Hst15All = 'hst15all',
  Hst13Canada = 'hst13canada',
  Vat0 = 'vat0',
  VatReverseCharge0 = 'vatreversecharge0',
  Vat77All = 'vat77all',
  Vat10All = 'vat10all',
  Vat14All = 'vat14all',
  Vat19All = 'vat19all',
  Vat20All = 'vat20all',
  Vat21All = 'vat21all',
  Vat22All = 'vat22all',
  Vat24All = 'vat24all',
  Vat25All = 'vat25all',
  Vat25Denmark = 'vat25denmark',
  Vat19Germany = 'vat19germany',
  Vat19Europe = 'vat19europe',
}

export interface FormFieldChoiceDto {
  id: string;
  shortLabel: TranslatableField;
  order: number;
  label: TranslatableField;
  hasQuantity: boolean;
  maxQuantity: number;
  remainingQuantity: number;
  cost?: number | null;
  lateCost?: number | null;
  lateCostDate?: string | null;
  startDate?: string;
  endDate?: string;
  taxRules: TaxRules;
  areTaxRulesAddedToCost: boolean;
}

export function isFieldChoiceAvailable(choice: FormFieldChoiceDto): boolean {
  const isEnoughQuantity = !choice.hasQuantity || choice.remainingQuantity > 0;
  const isDateRangeValid = isUtcNowInRange(choice.startDate, choice.endDate);
  return isEnoughQuantity && isDateRangeValid;
}

export function haveACost(choice: FormFieldChoiceDto): boolean {
  if (choice == null) return false;
  if (choice.cost! > 0) return true;
  return choice.lateCost! > 0;
}

export function getChoiceCost(choice: Pick<FormFieldChoiceDto, 'cost' | 'lateCost'>, currency: string) {
  const formattedCost = !choice.cost || choice.cost <= 0 ? '' : formatMoney(choice.cost, currency);
  const formattedLateCost = !choice.lateCost || choice.lateCost <= 0 ? '' : formatMoney(choice.lateCost, currency);
  if (formattedCost.length === 0 && formattedLateCost.length === 0) return '';

  if (formattedCost.length === 0 && formattedLateCost.length > 0) {
    return formatMoney(0, currency) + ' / ' + formattedLateCost;
  }

  return formattedLateCost.length === 0 ? formattedCost : formattedCost + ' / ' + formattedLateCost;
}
