export default {
  bookmark_filter: 'Bookmarked',
  bookmark: 'Bookmark',
  bookmarked: 'Bookmarked',
  comment: '{count} comments | {count} comment | {count} comments',
  contact: 'Contact',
  close: 'Close media viewer',
  disable_laser: 'Disable laser pointer',
  enable_laser: 'Enable laser pointer',
  follow: 'Follow',
  following: 'Following',
  list_layout: 'List view',
  no_presentations: 'No presentations',
  no_results: 'There are no presentations that match your filters.',
  other_information: 'Other information',
  grid_layout: 'Grid view',
  preparing_poster: 'Poster loading',
  presentation: 'No presentations | {count} presentation | {count} presentations',
  presentation_aria_label: 'View presentation {title}',
  presented_by: 'Presented by ',
  presenting_now: 'Presenting now',
  review_status_title: 'To review',
  schedule_filter: 'Session',
  total_count: 'presentation | presentations',
  upvote: 'upvotes | upvote | upvotes',
  unvote: 'unvote',
  poster: 'Poster',
  video: 'Video',
  slides: 'Slides',
  figures: 'Figures',
  view: 'View presentation',
  details: 'Details',
  contact_presenter_modal: {
    title: 'Contact Presenter',
    send_email: 'Send Email',
    success_message: 'Email sent.',
  },
  listing_options: {
    alphabetical: 'A - Z',
    numerical: '1 - 9',
    upvotes: 'Top voted',
  },
  schedule_presentations: {
    description: '* All times are based on',
    title: 'Scheduled in {count} session | Scheduled in {count} sessions',
  },
  search_placeholder: 'Search by title, abstract or author',
  see_all: 'See all Presentations',
};
