
import { Vue, Component, Prop, Watch, Ref } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import ActionSpinner from '../action/ActionSpinner.vue';
import Hint from '../Hint.vue';
import { Size, ProgressStep } from '../../types';
import ProgressSteps from '../design-system/molecules/ProgressSteps.vue';

@Component({
  components: {
    ActionSpinner,
    Hint,
    ProgressSteps,
  },
})
export default class FullPageModal extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string;
  @Prop({ type: String, default: '' }) readonly subtitle!: string;
  @Prop({ type: String, default: '' }) readonly titleCaption!: string;
  @Prop({ type: String, default: '' }) readonly subtitleCaption!: string;
  @Prop({ type: String, default: 'sm' }) readonly size!: string;
  @Prop({ type: Array, default: Array }) readonly steps!: string[];
  @Prop({ type: Number, default: 0 }) readonly stepIndex!: number;
  @Prop({ type: String, default: null }) readonly awaitMessage!: string;
  @Prop({ type: Array, default: null }) readonly progressSteps!: ProgressStep[] | null;
  @Prop(Boolean) readonly secondLastSubmit!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly nextDisabled!: boolean;
  @Prop(Boolean) readonly hideFooter!: boolean;
  @Prop(Boolean) readonly noGrow!: boolean;
  @Prop(Boolean) readonly isWaiting!: boolean;
  @Prop({ type: String, default: '' }) readonly disabledMessage!: string;
  @Prop({ type: String, default: null }) readonly actionButtonLabel!: string | null;

  @Ref() readonly bodyScroll?: HTMLElement;
  @Ref() readonly hiddenHandle?: HTMLButtonElement;

  Sizes = Size;
  showModal = false;
  simplebarInstance: SimpleBar | null = null;
  callBackFocusElement: HTMLButtonElement | null = null;

  get stepCount() {
    return this.steps.length;
  }

  get actionLabel() {
    return this.actionButtonLabel || this.$t('shared.modal.save');
  }

  get isStepModal() {
    return !!this.stepCount;
  }

  get subtitleContent() {
    if (this.isStepModal)
      return `${this.$t('shared.modal.step')} ${this.stepIndex + 1} / ${this.stepCount} · ${this.steps[this.stepIndex]}`;
    return this.subtitle ? this.subtitle : '';
  }

  get isSubmitStep() {
    if (!this.isStepModal) return true;
    if (this.secondLastSubmit && this.stepIndex + 1 === this.stepCount - 1) return true;
    return this.stepIndex + 1 === this.stepCount;
  }

  get showCancelButton() {
    if (!this.isStepModal) return true;
    return this.stepIndex === 0;
  }

  get rootClassNames() {
    const baseModifiers = {
      '-active': this.showModal,
      '-steps': this.isStepModal,
      '-has-progress-steps': this.showProgressSteps,
    };
    return [baseModifiers, `-${this.Sizes[this.size]}`];
  }

  get showProgressSteps() {
    return !!this.progressSteps && this.progressSteps.length > 0;
  }

  get showSubtitles() {
    if (this.showProgressSteps) return false;
    return !!(this.subtitleContent || this.subtitleCaption);
  }

  @Watch('showModal')
  async onShowModalChange() {
    await this.$nextTick();
    if (this.showModal) {
      this.$emit('open');
      if (this.bodyScroll) {
        this.simplebarInstance = new SimpleBar(this.bodyScroll);
        this.bodyScroll?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
      }
      this.hiddenHandle?.focus();
    } else {
      this.$emit('close');
      this.simplebarInstance?.unMount();
      this.callBackFocusElement?.focus();
      this.callBackFocusElement = null;
    }
  }

  public triggerFeedErrorBag(result): void {
    this.feedErrorBag(result);
  }

  public triggerVisibility(targetElement?: HTMLButtonElement | EventTarget | null): void {
    if (targetElement instanceof HTMLButtonElement) this.callBackFocusElement = targetElement;
    this.showModal = !this.showModal;
    this.$emit('visibilityChange');
  }

  public closeModal() {
    this.showModal = false;
  }

  public save(): void {
    this.$emit('save');
  }
}
