
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import { LocationWithRawQuery } from '../../helpers/router';
import ActionMixin from './ActionMixin';

@Component
export default class ActionLink extends mixins(ActionMixin) {
  @Prop({ default: null }) readonly href!: String | null;
  @Prop({ default: null }) readonly to!: LocationWithRawQuery | null;
  @Prop(Boolean) readonly newTab!: boolean;
  @Prop(Boolean) readonly replace!: boolean;

  get newTabAttributes() {
    return this.newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  }

  get linkClassNames() {
    return [{ ...this.elementClassNames, '-disabled': this.disabled }, `-${this.hierarchy} -${this.Sizes[this.size]}`];
  }
}
