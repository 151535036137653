
import { mixins, Component, Watch, Prop, Ref } from 'nuxt-property-decorator';
import SimpleBar from 'simplebar';
import { Collapsible } from '../layout';
import { GlobalFilterEvents, InputSelectOption } from '../../types';
import FilterMixin from './FilterMixin';

@Component({
  components: {
    Collapsible,
  },
})
export default class FilterSelect extends mixins(FilterMixin) {
  @Prop({ default: Array }) readonly options!: InputSelectOption[];
  @Prop({ default: '' }) readonly label!: string;
  @Prop(Boolean) readonly isMobile!: boolean;
  @Prop(Boolean) readonly selectFirstOption!: boolean;
  @Ref() readonly groupButton?: HTMLButtonElement;
  @Ref() readonly optionsMenu?: HTMLMenuElement;

  isOpen = this.isMobile;
  hasFocus = false;
  GlobalFilterEvents = GlobalFilterEvents;
  simplebarInstance: SimpleBar | null = null;
  scrollElement: HTMLElement | null = null;

  get selected() {
    const fallback = this.selectFirstOption ? String(this.options[0].id) : null;
    return (this.$route.query[this.queryKey] as string) || fallback;
  }

  get optionsIdList() {
    return this.options.map(({ id }) => String(id));
  }

  get selectedLabel() {
    const option = this.options.find(({ id }) => String(id) === this.selected);
    return option ? option.label : this.label;
  }

  @Watch('isOpen')
  onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen === wasOpen) return;

    if (isOpen) {
      document.body.addEventListener('click', this.closeMenu);
      this.$nuxt.$on(GlobalFilterEvents.SelectOpen, this.closeMenu);
    } else {
      document.body.removeEventListener('click', this.closeMenu);
      this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeMenu);
      this.groupButton?.focus();
    }
  }

  async mounted() {
    await this.$nextTick();
    if (!this.optionsMenu) return;
    this.simplebarInstance = new SimpleBar(this.optionsMenu);
    await this.$nextTick();
    this.scrollElement = this.simplebarInstance.getScrollElement();
    this.optionsMenu?.querySelector('[tabindex="0"]')?.removeAttribute('tabindex');
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.closeMenu);
    this.$nuxt.$off(GlobalFilterEvents.SelectOpen, this.closeMenu);
  }

  public toggleSelection(id: string | number) {
    const normalizedId = String(id);
    const isFirstOptionSelected = normalizedId === String(this.options[0].id);
    const shouldRemoveKey = this.selectFirstOption && isFirstOptionSelected;
    this.updateQuery(normalizedId, shouldRemoveKey);
    this.isOpen = false;
    this.groupButton?.focus();
  }

  public isOptionSelected(id: string | number) {
    return this.selected?.includes(String(id));
  }

  public onClickLabel() {
    this.isOpen = !this.isOpen;
    this.$nuxt.$emit(GlobalFilterEvents.SelectOpen);
  }

  public closeMenu() {
    this.isOpen = false;
  }

  public onMenuClose() {
    if (!this.scrollElement) return;
    this.scrollElement.scrollTop = 0;
  }
}
