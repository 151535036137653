
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { RawHtml } from '../../layout/';
import { Size, Color } from '../../../types';
import { TranslateResult } from '../../../types/i18n';

@Component({
  components: {
    RawHtml,
  },
})
export default class Alert extends Vue {
  @Prop({ type: String, default: Color.Default }) readonly color!: Color;
  @Prop({ type: String, default: 'md' }) readonly size!: string;
  @Prop({ type: String, default: null }) title!: TranslateResult | string;
  @Prop({ required: false, default: '' }) message!: TranslateResult | string;
  @Prop(String) readonly icon!: string;
  @Prop(Boolean) readonly dismissX!: boolean;
  @Prop(Boolean) readonly banner!: boolean;

  Sizes = Size;

  get rootClassNames() {
    return [
      `-${this.Sizes[this.size]}`,
      {
        '-banner': this.banner,
        [`-${this.color}`]: this.color !== Color.Default && this.color,
      },
    ];
  }
}
