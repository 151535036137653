import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { sumBy, uniqBy } from 'lodash-es';
import { StateChanger } from 'vue-infinite-loading';
import {
  isValidationError,
  MessageDto,
  MessageThreadType,
  TypeOrError,
  MessageReactionType,
  MessageReactionDto,
  isSuccess,
} from '../../services';
import { MessagingChannel } from '../../channels';

@Component({
  $_veeValidate: {
    validator: 'new',
  },
  template: '<div/>',
})
export default class BaseMessagingComponent extends Vue {
  @Prop({ required: true }) tokenObjectId!: string;
  @Prop({ default: MessageThreadType.QuestionsAndAnswers }) type!: MessageThreadType;
  @Prop(Boolean) isOrganizer!: boolean;
  @Prop({ default: null }) focusMessageId?: string;
  @Prop(Boolean) isCompact!: boolean;

  // messaging configuration
  channel: MessagingChannel | null = null;
  pageNumber = 1;
  pageSize = 25;
  isFirstLoadCompleted = false;

  // messaging data
  threadId: string | null = null;
  messages: MessageDto[] = [];

  // auto scroll
  canAutoScroll: Boolean = true;

  get token(): string {
    return `${this.tokenObjectId}.${this.type}`;
  }

  async created() {
    // configurations
    this.pageSize = this.type === MessageThreadType.QuestionsAndAnswers ? 5000 : 25;

    // real-time features
    if (this.$realtime) {
      this.channel = new MessagingChannel({
        connection: this.$realtime,
        objectId: this.tokenObjectId,
        type: this.type,
        onMessageAddedCallback: this.onMessageAdded,
        onMessageUpdatedCallback: this.onMessageUpdated,
        onMessageDeletedCallback: this.onMessageDeleted,
        onMessagesDeletedCallback: this.onMessagesDeleted,
        onMessageUpvotedCallback: this.onMessageUpvoted,
        onMessageUpvoteDeletedCallback: this.onMessageUpvoteDeleted,
        onMessageReactionAddedCallback: this.onMessageReactionAdded,
        onMessageReactionDeletedCallback: this.onMessageReactionDeleted,
      });
      this.channel.subscribe();
    }

    await this.fetchThread();

    // fetch messages - live chat will load messages using the infinite scroll feature
    if (this.$auth.loggedIn) await this.fetchMessages();

    // automatic scrolling to a specific message
    if (this.focusMessageId) {
      this.scrollToMessage(this.focusMessageId);
    }
  }

  beforeDestroy() {
    this.channel?.unsubscribe();
  }

  public async fetchMessages($liveChatState: StateChanger | null = null) {
    const response = this.threadId
      ? await this.$api.getThreadMessages(this.threadId, this.pageNumber, this.pageSize)
      : null;

    if (!this.isFirstLoadCompleted) this.isFirstLoadCompleted = true;

    if (!response?.items?.length) {
      $liveChatState?.complete();
      return;
    }

    // append messages to existing ones
    this.messages = uniqBy(this.messages.concat(response.items), 'id');
    $liveChatState?.loaded();

    // no more results
    if (response.totalCount === this.messages.length) {
      $liveChatState?.complete();
    }

    this.pageNumber++;
  }

  public scrollToMessage(id: string) {
    if (this.messages && this.messages.length > 0) {
      const domIdentifier = `[data-message-id="${id}"]`;
      this.$nextTick(() => this.$scrollTo(domIdentifier, { offset: -100 }));
    }
  }

  public async fetchThread(): Promise<void> {
    const thread = await this.$api.getThreadByToken(this.tokenObjectId, this.type);
    if (thread) this.threadId = thread.id;
  }

  public async createMessage(text: string): Promise<TypeOrError<boolean>> {
    if (!(await this.$validator.validateAll())) return new Error() as TypeOrError<boolean>;

    if (!this.threadId) await this.fetchThread();
    if (!this.threadId) {
      const thread = await this.$api.createObjectThread(this.type, this.tokenObjectId);
      if (isSuccess(thread)) this.threadId = thread.id;
    }
    if (!this.threadId) return new Error() as TypeOrError<boolean>;

    const response = await this.$api.createMessage(this.threadId, text);
    if (isValidationError(response)) {
      this.feedErrorBag(response);
    }

    return response;
  }

  public onMessageAdded(token: string, message: MessageDto) {
    // ignore message from another websocket group
    if (token !== this.token) return;

    message.reactions = [];
    if (message.parentId) {
      const parent = this.messages.find(x => x.id === message.parentId);
      parent!.messages.push(message);
    } else {
      message.messages = [];
      this.messages.push(message);
    }

    this.$emit(
      'messageCountUpdate',
      sumBy(this.messages, (m: MessageDto) => m.messages.length + 1),
    );

    if (message.userId !== this.$auth.user.id) {
      this.$emit('messageAdded');
    }

    if (this.type === MessageThreadType.LiveChat && this.canAutoScroll) {
      this.autoScrollDown();
    }
  }

  public onMessageUpdated(message: MessageDto) {
    const parentIndex = this.messages.findIndex(x => x.id === message.parentId);
    const index =
      parentIndex === -1
        ? this.messages.findIndex(x => x.id === message.id)
        : this.messages[parentIndex].messages.findIndex(x => x.id === message.id);
    if (index === -1) return;
    if (parentIndex === -1) {
      this.messages.splice(index, 1, this.persistUserProperties(this.messages[index], message));
    } else {
      const originalMessage = this.messages[parentIndex].messages[index];
      this.messages[parentIndex].messages.splice(index, 1, this.persistUserProperties(originalMessage, message));
    }
  }

  public onMessageDeleted(messageId: string, parentId?: string) {
    const parentIndex = this.messages.findIndex(x => x.id === parentId);
    const index =
      parentIndex === -1
        ? this.messages.findIndex(x => x.id === messageId)
        : this.messages[parentIndex].messages.findIndex(x => x.id === messageId);
    if (index === -1) return;
    parentIndex === -1 ? this.messages.splice(index, 1) : this.messages[parentIndex].messages.splice(index, 1);

    if (this.type === MessageThreadType.QuestionsAndAnswers) {
      this.$emit(
        'messageCountUpdate',
        sumBy(this.messages, (m: MessageDto) => m.messages.length + 1),
      );
    }
  }

  public onMessageUpvoted(messageId: string, userId: string) {
    const message = this.getMessageById(messageId);
    if (!message) return;
    message.upvoteCount++;
    if (userId === this.$auth.user.id) {
      message.isUpvotedByCurrentUser = true;
    }
  }

  public onMessageUpvoteDeleted(messageId: string, userId: string) {
    const message = this.getMessageById(messageId);
    if (!message) return;
    message.upvoteCount--;
    if (userId === this.$auth.user.id) {
      message.isUpvotedByCurrentUser = false;
    }
  }

  public onMessageReactionAdded(messageId: string, type: MessageReactionType, userId: string) {
    const message = this.getMessageById(messageId);
    if (!message) return;
    message.reactions.push(<MessageReactionDto>{ type, userId });
  }

  public onMessageReactionDeleted(messageId: string, type: MessageReactionType, userId: string) {
    const message = this.getMessageById(messageId);
    if (!message) return;
    const index = message.reactions.findIndex(x => x.type === type && x.userId === userId);
    message.reactions.splice(index, 1);
  }

  public onMessagesDeleted(token: string) {
    if (this.token === token) this.messages = [];
  }

  public autoScrollDown() {
    this.$nextTick(() => {
      (this.$refs.scrollUtil as HTMLElement)?.scrollIntoView();
    });
  }

  private getMessageById(messageId: string): MessageDto | null {
    // Only first level can get upvoted
    for (const [index, message] of this.messages.entries()) {
      if (message.id === messageId) {
        return this.messages[index];
      }
    }
    return null;
  }

  // Since dto sent by SignalR does not include user data, we must persist them
  private persistUserProperties(olderMessage: MessageDto, newerMessage: MessageDto): MessageDto {
    newerMessage.upvoteCount = olderMessage.upvoteCount;
    newerMessage.isUpvotedByCurrentUser = olderMessage.isUpvotedByCurrentUser;
    newerMessage.messages = olderMessage.messages;
    newerMessage.reactions = olderMessage.reactions;
    return newerMessage;
  }
}
