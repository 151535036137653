
import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { UserProfileDto, PageType, FormDto, FormEntryDto } from '../../services';
import { LayoutModes } from '../../types';
import { PresentationElement } from '../presentations';
import PresentationCard from '../presentations/PresentationCard.vue';
import ParticipantOtherInformations from './ParticipantOtherInformations.vue';

@Component({
  components: {
    ParticipantOtherInformations,
    PresentationCard,
  },
})
export default class ParticipantBody extends Vue {
  @Prop({ required: true }) readonly linkName!: string;
  @Prop({ required: true }) readonly user!: UserProfileDto;
  @Prop({ required: true }) readonly presentations!: PresentationElement[];
  @Prop({ required: true }) readonly pageType!: PageType;
  @Prop({ required: true }) readonly form!: FormDto | null;
  @Prop({ required: true }) readonly submissionForm!: FormDto | null;
  @Prop({ required: true }) readonly formEntry!: FormEntryDto;
  @Prop(Boolean) readonly isParticipant!: boolean;
  @Prop(Boolean) readonly isOrganizer!: boolean;
  @Prop(Boolean) readonly isVotingEnabled!: boolean;

  readonly LayoutModes = LayoutModes;
}
