/* eslint import/first: 0 */
import Vue from 'vue';

// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

// vue-infinite-scroll
import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);
