export enum Size {
  xs = 'x-small',
  sm = 'small',
  md = 'medium',
  lg = 'large',
}

export enum Saturation {
  None = 'none',
  Light = 'light',
  Dark = 'dark',
}

export enum Color {
  Gray = 'gray',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Default = 'default',
}

export enum IconTheme {
  Square = 'square',
  Circle = 'circle',
  CircleOutline = 'circle-outline',
}

export enum Alignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export interface ProgressStep {
  title: string;
  subtitle?: string;
}
